.registryAndWebsiteShareModal__content {
  padding: 60px 72px;
}
.registryAndWebsiteShareModal__content .zola-ui.link {
  border-bottom: 2px solid transparent;
}
.registryAndWebsiteShareModal__title {
  font-size: 32px;
  color: #21201f;
  font-weight: 600;
}
.registryAndWebsiteShareModal__label {
  color: #21201f;
  font-weight: 600;
}
.registryAndWebsiteShareModal__copyUrl {
  margin: 40px auto 0;
  max-width: 460px;
}
.registryAndWebsiteShareModal__copyUrlBlock {
  display: flex;
  align-items: center;
  margin-top: 4px;
}
.registryAndWebsiteShareModal__url {
  border-radius: 4px;
  border: solid 2px #d9d9d9;
  background-color: #f7f7f7;
  width: 336px;
  padding: 9px;
  margin-right: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.registryAndWebsiteShareModal__previewLink {
  display: inline-flex;
  align-items: center;
  margin-top: 12px;
}
.registryAndWebsiteShareModal__preview--icon {
  font-size: 20px;
}
.registryAndWebsiteShareModal__notVisible {
  margin: 40px auto 0;
  background-color: #fffade;
  padding: 20px;
  max-width: 460px;
}
.registryAndWebsiteShareModal__footer {
  padding: 20px;
  background-color: #c9d6ec;
}
.registryAndWebsiteShareModal__footer--label {
  margin-right: 12px;
}
