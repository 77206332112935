/**
 * @prettier
 */
@media screen and (max-width: 767px) {
}
@media (min-width: 768px) {
}
@media (min-width: 768px) {
}
@media (max-width: 767px) {
}
@media (min-width: 768px) {
}
@media (min-width: 768px) {
}
@media (max-width: 767px) {
}
@media (max-width: 767px) {
}
:local .hideOptionsOnMobile :global(.toggle-list) {
  display: inline-block;
}
@media (max-width: 767px) {
  :local .hideOptionsOnMobile :global(.toggle-list) {
    display: none;
  }
}
:local .hideOptionsOnMobile :global(.detail-box) img {
  display: none;
}
:local .box :global(.toggle-list__item) {
  display: inline-block;
}
.imageTag {
  background-color: #ebf1f9 !important;
  border: none !important;
  border-radius: 4px;
  color: #276588 !important;
  font-weight: 700;
  margin: 8px 0;
  padding: 3px 6px;
}
.imageTagBox {
  width: 100%;
}
