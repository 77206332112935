.apPlpPage {
  align-content: center;
  background-color: #ebf4f9;
  background-image: url('../../../../../../../assets/images/invitations/single-sample-modal/ap-web-plp.svg');
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 4px;
  flex-direction: row;
  margin: 16px 0 0 0;
  padding: 20px 24px;
}
@media (max-width: 767px) {
  .apPlpPage {
    align-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
  }
}
.apPlpPageTitle {
  color: #21201f;
  font-family: 'circular', serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  margin-top: 0;
  text-align: left;
}
@media (max-width: 767px) {
  .apPlpPageTitle {
    text-align: center;
  }
}
.apPlpPageText {
  color: #505050;
  font-family: 'circular', serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
}
@media (max-width: 767px) {
  .apPlpPageText {
    text-align: center;
    width: 100%;
  }
}
