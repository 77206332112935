.container {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  height: 448px;
}
@media (max-width: 767px) {
  .container {
    height: 50vh;
  }
}
