.container {
  padding: 0 32px 32px;
}
.container h2 {
  font-size: 24px;
  font-weight: 600;
  margin: 20px 0 8px 0;
}
.container p {
  font-size: 16px;
  color: #505050;
}
.hero {
  margin: 0 -32px;
}
.actions {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
}
.link {
  font-size: 16px;
}
