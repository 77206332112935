@media screen and (max-width: 767px) {
}
@media (min-width: 768px) {
}
@media (min-width: 768px) {
}
@media (max-width: 767px) {
}
@media (min-width: 768px) {
}
@media (min-width: 768px) {
}
@media (max-width: 767px) {
}
@media (max-width: 767px) {
}
#publish-website-modalv2 .modal-body,
#registry-publish-modalv2 .modal-body {
  padding-bottom: 35px;
}
#publish-website-modalv2 .modal-body .link,
#registry-publish-modalv2 .modal-body .link {
  padding: 10px;
}
#publish-website-modalv2 .modal-body .link :hover,
#registry-publish-modalv2 .modal-body .link :hover {
  color: #4a4a4a;
}
#publish-website-modalv2 .modal-body .link :active,
#registry-publish-modalv2 .modal-body .link :active {
  color: #003b58;
  opacity: 0.9;
}
#publish-website-modalv2 .modal-body .go-live-content a:hover,
#registry-publish-modalv2 .modal-body .go-live-content a:hover {
  cursor: pointer;
}
#publish-website-modalv2 .modal-body .go-live-content .go-live-icon,
#registry-publish-modalv2 .modal-body .go-live-content .go-live-icon {
  margin: 10px;
  max-height: 80px;
}
@media (min-width: 768px) {
  #publish-website-modalv2 .modal-body .go-live-content .go-live-icon,
  #registry-publish-modalv2 .modal-body .go-live-content .go-live-icon {
    max-height: 102px;
  }
}
#publish-website-modalv2 .modal-body .go-live-content h3,
#registry-publish-modalv2 .modal-body .go-live-content h3 {
  font-family: 'circular', serif;
  font-weight: 400;
  margin: 10px 10px 20px 10px;
}
@media (min-width: 768px) {
  #publish-website-modalv2 .modal-body .go-live-content h3,
  #registry-publish-modalv2 .modal-body .go-live-content h3 {
    font-size: 30px;
    margin: 10px 60px;
  }
}
@media (min-width: 768px) {
  #publish-website-modalv2 .modal-body .go-live-content .go-live-url,
  #registry-publish-modalv2 .modal-body .go-live-content .go-live-url {
    padding: 0 50px 0 50px;
  }
}
#publish-website-modalv2 .modal-body .go-live-content .go-live-url .input,
#registry-publish-modalv2 .modal-body .go-live-content .go-live-url .input {
  align-items: center;
  background-color: #f7f7f7;
  border: 1px solid #e5e5e5;
  display: flex;
  flex-direction: row;
  height: 38px;
  justify-content: space-between;
  margin-bottom: 30px;
  margin-top: 10px;
  width: 100%;
}
#publish-website-modalv2 .modal-body .go-live-content .go-live-url .input .copy-link,
#registry-publish-modalv2 .modal-body .go-live-content .go-live-url .input .copy-link {
  font-size: 14px;
}
#publish-website-modalv2 .modal-body .go-live-content .go-live-url .input .url-text,
#registry-publish-modalv2 .modal-body .go-live-content .go-live-url .input .url-text {
  overflow: hidden;
  padding: 10px 0 10px 10px;
  text-overflow: ellipsis;
  white-space: nowrap;
}
#publish-website-modalv2 .modal-body .go-live-content .go-live-button,
#registry-publish-modalv2 .modal-body .go-live-content .go-live-button {
  margin-bottom: 20px;
}
@media (max-width: 767px) {
  #publish-website-modalv2 .modal-body .go-live-content .go-live-button,
  #registry-publish-modalv2 .modal-body .go-live-content .go-live-button {
    width: 100%;
  }
}
#registry-publish-modalv3 h4 {
  font-family: 'circular', serif;
  font-size: 24px;
  font-weight: bold;
}
#registry-publish-modalv3 span {
  color: #0e0e0e;
  font-size: 12px;
  margin: 5px;
}
#registry-publish-modalv3 .go-live-header {
  border-bottom: solid 1px #d9d9d9;
  padding: 20px;
  text-align: center;
}
#registry-publish-modalv3 .go-live-header .go-live-left-sparkle {
  display: inline-block;
  margin-top: 15px;
  vertical-align: top;
}
#registry-publish-modalv3 .go-live-header .go-live-right-sparkle {
  display: inline-block;
  margin-top: 26px;
  vertical-align: top;
}
#registry-publish-modalv3 .go-live-header .go-live-title {
  display: inline-block;
}
@media (max-width: 767px) {
  #registry-publish-modalv3 .go-live-header .go-live-title {
    font-size: 20px;
  }
}
#registry-publish-modalv3 .go-live-body {
  padding: 20px 0 50px 50px;
}
@media (max-width: 767px) {
  #registry-publish-modalv3 .go-live-body {
    padding-bottom: 15px;
    padding-left: 15px;
    padding-right: 150px;
  }
}
@media (max-width: 600px) {
  #registry-publish-modalv3 .go-live-body {
    padding-bottom: 15px;
    padding-left: 15px;
    padding-right: 15px;
  }
}
#registry-publish-modalv3 .go-live-body img {
  margin-right: 15px;
}
@media (max-width: 767px) {
  #registry-publish-modalv3 .go-live-body img {
    margin-right: 0;
  }
}
#registry-publish-modalv3 .go-live-body h4 {
  width: 300px;
}
@media (max-width: 767px) {
  #registry-publish-modalv3 .go-live-body h4 {
    font-size: 28px;
    width: 250px;
  }
}
@media (max-width: 600px) {
  #registry-publish-modalv3 .go-live-body h4 {
    font-size: 24px;
    width: 220px;
  }
}
@media (max-width: 767px) {
  #registry-publish-modalv3 .go-live-body p {
    font-size: 18px;
    margin-top: 12px;
  }
}
#registry-publish-modalv3 .go-live-body .title {
  font-family: 'Sakura Regular', 'Georgia', serif;
  font-size: 54px;
  letter-spacing: 0;
  line-height: 15px;
  margin-bottom: 35px;
  margin-top: 20px;
}
#registry-publish-modalv3 .go-live-body .go-live-section-container {
  margin-top: 20px;
}
#registry-publish-modalv3 .go-live-body .publish-registry-modal-v3__copy-img {
  margin-left: -60px;
  margin-top: 10px;
  transform: rotate(360deg);
}
#registry-publish-modalv3 .go-live-body .exchange-section {
  padding-right: 0;
}
#registry-publish-modalv3 .go-live-body .exchange-section h4 {
  width: 250px;
}
@media (max-width: 767px) {
  #registry-publish-modalv3 .go-live-body .exchange-section h4 {
    width: 200px;
  }
}
#registry-publish-modalv3 .go-live-body .exchange-section .sub-title {
  margin-top: -10px;
}
@media (max-width: 767px) {
  #registry-publish-modalv3 .go-live-body .exchange-section .sub-title {
    width: 100px;
  }
}
