.modalHeader {
  border-bottom: 1px solid #d9d9d9;
}
.modalTitle {
  padding: 20px 40px;
  font-weight: 600;
  margin: 0;
}
.modalBody {
  padding: 20px 40px;
}
.modalBody :global(.suggestion-list) {
  display: none;
}
.backButton {
  background: none;
  border: none;
  color: #0075ae;
  font-size: 12px;
  font-weight: 600;
  height: 26px;
  line-height: 26px;
  letter-spacing: 1.2px;
  margin: 6px 0 0 10px;
  text-transform: uppercase;
}
.backButton:hover {
  text-decoration: underline;
}
.eventFields {
  display: flex;
  flex-direction: row;
  padding-bottom: 20px;
}
.eventFields > :global(.v2-render-field) {
  width: 40%;
  margin-right: 12px;
}
.eventFields > :global(.dropdown-v2__container) {
  width: 60%;
}
.buttonSet {
  border-top: 1px solid #d9d9d9;
  padding: 20px 40px 20px;
  margin: -21px -40px 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.saveBtn {
  margin-left: 12px;
}
.form {
  display: block;
}
.form :global(.dropdown-v2__selection-box) {
  margin-top: 4px;
}
.form :global(.eventForm__timeFields > .form-group),
.form :global(.eventForm__timeFields > .dropdown-v2__container) {
  width: 25%;
}
.suggestionsActive .form,
.suggestionsActive .buttonSet {
  display: none;
}
.suggestionsActive :global(.suggestion-list) {
  display: block;
}
.zoomInfo {
  padding: 32px 0;
  border-top: 1px solid #d9d9d9;
}
.zoomInfoItem {
  color: #505050;
}
.zoomInfoItem:last-of-type {
  margin-bottom: 0;
}
.zoomBold {
  font-weight: 600;
  color: #21201f;
}
.zoomPwText {
  margin-top: 12px;
  margin-bottom: 0;
}
