.wildcardPosition {
  background: #fff;
  border-radius: 100%;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0);
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
  padding: 2px;
  position: relative;
}
.wildcardPicker {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 100%;
  height: 40px;
  margin: 2px;
  width: 40px;
}
.wildcardIndicator {
  border: 2px solid transparent;
  border-radius: 100%;
  margin: 0 2px;
}
.wildcardIndicatorActive {
  border: 2px solid #505050;
}
.pickerBackground {
  bottom: 0;
  height: 100vh;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 100;
}
.pickerPosition {
  left: 172px;
  position: absolute;
  top: -90px;
  z-index: 101;
}
.pickerPosition::after {
  background: #fff;
  border-bottom: 2px solid #d9d9d9;
  border-left: 2px solid #d9d9d9;
  border-width: 0 2px 2px 0;
  content: '';
  display: block;
  height: 1em;
  left: -0.6em;
  margin-left: 0;
  margin-top: -0.5em;
  position: absolute;
  top: 50%;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  width: 1em;
  z-index: 10000;
}
.colorPickerContainer {
  align-items: center;
  display: flex;
}
