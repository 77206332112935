.toggleInput {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.toggleLabel {
  margin-right: 8px;
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 600;
  color: #21201f;
  text-transform: none;
  letter-spacing: normal;
}
.toggleSection {
  border-top: 1px solid #d9d9d9;
  border-bottom: 1px solid #d9d9d9;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-bottom: 20px;
}
.toggleSection :global(.passcode-account-settings) {
  display: none;
}
.toggleSection :global(.passcode-input) {
  margin-top: 0!important;
  justify-content: flex-start !important;
}
.toggleSection :global(.passcode-toggle) {
  margin-bottom: 0!important;
  padding-top: 0!important;
}
.toggleSection :global(.passcode-heading > span) {
  margin-right: 8px;
  font-weight: 600 !important;
}
