.container {
  padding: 96px 20px 40px;
  position: relative;
  display: flex;
  flex-direction: column;
}
@media (min-width: 768px) {
  .container {
    text-align: center;
    padding: 52px 52px 40px;
    display: block;
  }
}
.title {
  margin-top: 0;
  margin-bottom: 40px;
  font-weight: 700;
}
.tiles {
  display: flex;
  margin-bottom: 40px;
}
@media (max-width: 767px) {
  .tiles {
    flex-direction: column;
  }
}
.tile {
  flex: 0 1 33.33%;
  padding: 0 15px;
  font-size: 16px;
  line-height: 24px;
}
@media (max-width: 767px) {
  .tile {
    display: flex;
    align-items: center;
    padding: 0;
    margin-bottom: 30px;
  }
}
.img {
  max-width: 100%;
  max-height: 100px;
  display: block;
}
@media (min-width: 768px) {
  .img {
    margin: 0 auto 20px;
  }
}
.dek {
  color: #505050;
  font-weight: 600;
}
@media (max-width: 767px) {
  .dek {
    margin-left: 20px;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
  }
}
.back {
  position: fixed;
  top: 20px;
  left: 20px;
}
@media (min-width: 768px) {
  .back {
    display: none;
  }
}
