/* stylelint-disable-next-line selector-pseudo-class-no-unknown */
:global(.scalable-container) {
  box-shadow: none !important;
}
:global(.scalable-container) svg {
  position: absolute;
}
.replayCTA {
  bottom: 10%;
  opacity: 0;
  position: absolute;
  /* stylelint-disable-next-line selector-pseudo-class-no-unknown */
}
@media screen and (max-width: 991px) {
  .replayCTA {
    bottom: 10px;
  }
}
.replayCTA :global(.v2-button) {
  height: 42px;
}
.showReplayCTA {
  opacity: 1;
  transition: opacity 0.3s ease-in;
  transition-delay: 2s;
}
.rotateSVG {
  transform: rotate(90deg);
  transform-box: fill-box;
  transform-origin: center;
}
