@media screen and (max-width: 767px) {
}
@media (min-width: 768px) {
}
@media (min-width: 768px) {
}
@media (max-width: 767px) {
}
@media (min-width: 768px) {
}
@media (min-width: 768px) {
}
@media (max-width: 767px) {
}
@media (max-width: 767px) {
}
#edit-guest-group-modal .modal-header h3 {
  padding-left: 20px;
}
#edit-guest-group-modal .modal-header .modal-button-right {
  text-align: right;
}
#edit-guest-group-modal .modal-body {
  padding: 0px;
}
#edit-guest-group-modal .modal-body .container-fluid {
  padding-left: 0px;
  padding-right: 0px;
}
#edit-guest-group-modal .modal-body form {
  padding-bottom: 30px;
}
#edit-guest-group-modal .modal-body form .tabs-labels {
  padding-left: 80px;
}
@media (max-width: 767px) {
  #edit-guest-group-modal .modal-body form .tabs-labels {
    padding-left: 40px;
  }
}
#edit-guest-group-modal .modal-body form .tabs-content {
  padding-left: 80px;
  padding-right: 80px;
}
@media (max-width: 767px) {
  #edit-guest-group-modal .modal-body form .tabs-content {
    padding-left: 40px;
    padding-right: 40px;
  }
}
#edit-guest-group-modal .modal-body form .edit-guest-submit {
  margin-left: 100px;
}
#edit-guest-group-modal .modal-body form .row.form-group {
  margin-bottom: 15px;
}
#edit-guest-group-modal .modal-body form .row.form-group .guest-unknown-zolaicon {
  position: relative;
  top: 9px;
  right: 29px;
}
@media (max-width: 991px) {
  #edit-guest-group-modal .modal-body form .row.form-group .col-xs-12 {
    margin-bottom: 10px;
  }
}
#edit-guest-group-modal .modal-body form .row.form-group .col-xs-12 .title-container .with-tooltip select {
  width: 105px;
}
#edit-guest-group-modal .modal-body form .row.form-group .col-xs-12 .form-label .with-tooltip .title {
  text-transform: uppercase;
  font-size: 12px;
}
#edit-guest-group-modal .modal-body form .row.form-group .col-xs-12 .form-label .with-tooltip .checkbox input .input-override .zolaicon.zolaicon-checkmark {
  font-size: 12px;
}
@media (min-width: 992px) {
  #edit-guest-group-modal .modal-body form .form-group .with-tooltip .wedding-label {
    position: relative;
    top: 3px;
  }
}
#edit-guest-group-modal .generic-accordion .accordion-section:first-child {
  border-top: solid 1px #d9d9d9;
}
#edit-guest-group-modal .envelope-recipient-container .envelope {
  display: flex;
}
#edit-guest-group-modal .envelope-recipient-container .envelope-recipient {
  font-weight: 700;
  display: flex;
  flex-direction: column;
}
#edit-guest-group-modal .envelope-recipient-container i.glyphicon-pencil {
  font-size: 16px;
  margin-left: 20px;
  color: #7f7f7f;
}
#edit-guest-group-modal .envelope-recipient-container i.glyphicon-pencil:hover {
  color: #0e0e0e;
  cursor: pointer;
}
#edit-guest-group-modal #edit-guest-group-envelope_recipient_override {
  width: 70%;
}
#edit-guest-group-modal .edit-guest-group-form .form-group {
  margin-bottom: 15px;
}
#edit-guest-group-modal .edit-guest-group-form .edit-guest-group-submit-container:before,
#edit-guest-group-modal .edit-guest-group-form .edit-guest-group-submit-container:after {
  display: table;
  content: " ";
}
#edit-guest-group-modal .edit-guest-group-form .edit-guest-group-submit-container:after {
  clear: both;
}
#edit-guest-group-modal .edit-guest-group-form .edit-guest-group-submit-container:before,
#edit-guest-group-modal .edit-guest-group-form .edit-guest-group-submit-container:after {
  display: table;
  content: " ";
}
#edit-guest-group-modal .edit-guest-group-form .edit-guest-group-submit-container:after {
  clear: both;
}
#edit-guest-group-modal .edit-guest-group-form .edit-guest-group-submit-container .edit-guest-group-submit {
  padding-right: 80px;
}
#edit-guest-group-modal .edit-guest-group-form .rsvp-status.no-invites {
  margin: 0 auto;
  padding-top: 80px;
  padding-bottom: 80px;
  text-align: center;
}
#edit-guest-group-modal .edit-guest-group-form .rsvp-status.no-invites .no-invites-copy {
  margin: 0 auto;
  font-size: 16px;
  max-width: 500px;
}
.tabs ul.tabs-labels {
  padding-left: 0;
  list-style: none;
  margin-left: -5px;
}
.tabs ul.tabs-labels > li {
  display: inline-block;
  padding-right: 5px;
  padding-left: 5px;
}
.tabs .tabs-content .tabs-pane .with-tooltip.edit-guest-name {
  display: block;
}
.tabs .tabs-content .tabs-pane .with-tooltip.edit-guest-name:before,
.tabs .tabs-content .tabs-pane .with-tooltip.edit-guest-name:after {
  bottom: 100%;
  left: 32%;
}
.guest-delete {
  position: absolute;
  right: -10px;
  cursor: pointer;
  line-height: 38px;
}
