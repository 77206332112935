.container {
  background: #ffffff;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  height: fit-content;
  margin-bottom: 20px;
  min-height: fit-content;
  padding: 20px;
}
.container:last-child {
  margin-bottom: 0;
}
.container.active {
  border: 2px solid #0075ae;
}
.container.inactive {
  border: 2px solid #d9d9d9;
}
.container:hover {
  border: 2px solid #0075ae;
  cursor: pointer;
}
.customizationsContainer {
  margin-top: 12px;
}
.detailContainer {
  padding-left: 12px;
}
.detailContainer .header {
  color: #21201f;
  font-family: 'circular', serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
  margin: 0;
}
.detailContainer .lastUpdated {
  font-family: 'circular', serif;
  font-size: 12px;
  font-style: normal;
  font-weight: normal;
  margin: 0;
}
.detailContainer .customization {
  align-items: center;
  color: #505050;
  display: flex;
  font-family: 'circular', serif;
  font-size: 14px;
  line-height: 24px;
  margin: 0;
}
.detailContainer .customization b {
  color: #21201f;
  font-weight: 600;
}
.imageContainer {
  margin-bottom: 12px;
  margin-left: auto;
  margin-right: 12px;
  margin-top: 12px;
  padding-left: 12px;
}
.imageContainer.portrait {
  height: auto;
  width: 90px;
}
@media (max-width: 767px) {
  .imageContainer.portrait {
    width: 62px;
    height: auto;
  }
}
.imageContainer.landscape {
  height: 80px;
  width: 102px;
}
@media (max-width: 767px) {
  .imageContainer.landscape {
    width: 73px;
    height: 57px;
  }
}
.imageContainer.even {
  height: 80px;
  width: 80px;
}
@media (max-width: 767px) {
  .imageContainer.even {
    height: 57px;
    width: 57px;
  }
}
.mainImage {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
  max-width: 100%;
}
