@media screen and (max-width: 767px) {
}
@media (min-width: 768px) {
}
@media (min-width: 768px) {
}
@media (max-width: 767px) {
}
@media (min-width: 768px) {
}
@media (min-width: 768px) {
}
@media (max-width: 767px) {
}
@media (max-width: 767px) {
}
.previewBox {
  align-items: center;
  background: #fff;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin: auto;
  padding: 12px;
  position: relative;
}
:local .colorPickerBox {
  margin: 20px auto 0 auto;
  min-height: 28px;
}
:local .colorPickerBox .invisible {
  visibility: hidden;
}
:local .colorPickerBox :global(.product-color-picker) {
  display: flex;
  justify-content: center;
  margin: auto;
}
.centeredBox {
  align-items: center;
  display: flex;
  flex: 1 1 100%;
  flex-direction: column;
}
@media (max-width: 767px) {
  .centeredBox {
    display: block;
    flex: none;
  }
}
.imageLayer {
  background-repeat: no-repeat;
  background-size: 100%;
  position: absolute;
}
.imageLayer.rounded {
  border-radius: 8px;
}
.imageLayer.border {
  border: 1px solid #d9d9d9;
}
.textLayer {
  position: absolute;
}
.canvas {
  position: relative;
  transform-origin: left top;
}
.box {
  align-items: center;
  display: flex;
  flex: 1 1 100%;
  flex-direction: column;
}
.nonEditableImg {
  max-height: 280px;
}
.pageDots {
  align-items: center;
  bottom: -16px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: absolute;
  width: 100%;
  z-index: 1000;
}
.pageDot {
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.2);
  height: 8px;
  margin: 0 2px;
  width: 8px;
}
.pageDot.active {
  background-color: #0075ae;
}
:local .photoActions {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  margin: auto;
  position: relative;
  width: 100%;
}
:local .photoActions :global(button.v2-button.primary-button) {
  background-color: #21201f;
  border: 2px solid #ffffff;
}
:local .photoActions > div {
  margin: 20px 0;
  width: 60%;
}
:local .pageButton {
  position: absolute;
  top: 49%;
  z-index: 1000;
}
:local .pageButton :global(.zola-ui.chevron-button.chevron-button--tertiary) {
  background-color: #ffffff;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
}
:local .pageButton.disabled :global(.zola-ui.chevron-button.chevron-button--tertiary) {
  color: #d9d9d9;
}
.pageButtonLast {
  left: -16px;
}
.pageButtonNext {
  right: -16px;
}
.canvasOverlay {
  background-color: #21201f;
  height: 100%;
  opacity: 0;
  position: absolute;
  transition: opacity 0.3s;
  width: 100%;
  z-index: 1000;
}
.canvasOverlay:hover {
  opacity: 0.7;
}
@media (max-width: 767px) {
  .canvasOverlay:hover {
    opacity: 0;
  }
}
.previewLoading {
  background-color: #767676;
  height: 100%;
}
