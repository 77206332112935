.stepOneWrapper {
  align-items: flex-start;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  width: 100%;
}
.stepOneTitle {
  font-size: 24px;
  font-weight: 600;
  line-height: 28px;
  margin-bottom: 12px;
}
.stepOneSubTitle {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 40px;
}
.progressWrapper {
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  margin-bottom: 40px;
  width: 100%;
}
.infoWrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
  max-width: 422px;
}
.progressContent {
  margin-right: 20px;
}
.progressTitle {
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
}
.progressPercentage {
  margin-left: auto;
}
.progressBar {
  background: #eee;
  border-radius: 16px;
  height: 8px;
  position: relative;
  width: 100%;
}
.progressInner {
  animation-duration: 3.25s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: shimmer;
  animation-timing-function: linear;
  background: #06b025 linear-gradient(to left, #06b025 0%, #06b025 30%, #5faf6c 45%, #c5ddc6 50%, #5faf6c 60%, #06b025 70%, #06b025 100%) no-repeat;
  background-size: 1000px 100%;
  border-radius: 16px;
  display: inline-block;
  height: 100%;
  position: absolute;
  transition: width 0.4s ease;
}
.progressInfo {
  display: flex;
  margin-bottom: 6px;
}
.progressClose {
  background: transparent;
  border: 0;
  color: #a8a8aa;
  cursor: pointer;
  font-size: 26px;
  font-weight: 100;
  height: 26px;
  line-height: 1;
  margin-left: 20px;
  padding: 0;
  width: 26px;
}
.modalBottom {
  margin-left: auto;
  margin-top: 40px;
}
.textWrapper,
.titleText {
  font-family: 'circular', serif;
}
.titleText {
  color: #21201f;
  font-size: 24px;
  font-weight: 600;
  line-height: 28px;
  margin-bottom: 12px;
}
.instructionList {
  color: #505050;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 20px;
  padding-left: 30px;
}
.textTips {
  margin-bottom: 0;
}
.stepTwoContent :global(.preview-ui) {
  height: 148px;
  margin: 0;
  width: 167px;
}
.stepTwoContent :global(.main-content-wrapper) {
  height: 120px;
  justify-content: flex-start;
  padding: 16px;
  width: 167px;
}
.stepTwoContent :global(.additional-information-wrapper) {
  display: none;
}
.stepTwoContent :global(.canvas-preview) {
  height: 54px;
  width: 41px;
}
.stepTwoContent :global(.scalable-container) {
  transform: translate(-50%, -50%) scale(0.0942754) !important;
}
.topContent {
  align-items: flex-start;
  display: flex;
  flex-flow: row;
  justify-content: flex-end;
  margin-bottom: -28px;
}
.bulbIcon {
  margin-right: auto;
}
.previewWrapper {
  align-items: flex-start;
  border-radius: 4px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  max-width: 167px;
  padding: 0;
  width: 100%;
}
.previewHeader {
  align-items: flex-start;
  background-color: #E5E5E5;
  border-radius: 4px 4px 0 0;
  display: flex;
  flex-flow: row nowrap;
  height: 16px;
  justify-content: center;
  padding-top: 4px;
  width: 100%;
}
.searchBar {
  background-color: #ffffff;
  height: 8px;
  width: 124px;
}
.zolaIcon {
  background-image: url("../../../../../../assets/images/digitalSaveTheDate/modalIcon.png");
  background-position: top center;
  background-repeat: no-repeat;
  background-size: contain;
  display: block;
  height: 13px;
  margin-left: 5px;
  width: 9px;
}
.downloadModalContent {
  align-items: flex-start;
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  padding: 15px 12px 8px;
  width: 100%;
}
.columnLeft,
.columnRight {
  align-items: flex-start;
}
.columnRight {
  margin-left: 16px;
  max-width: 89px;
  width: 100%;
}
.columnRight > span:nth-child(2),
.columnRight > span:nth-child(7) {
  background-color: transparent;
  margin: 3px 0;
}
.columnRight > span:nth-child(8),
.columnRight > span:nth-child(9),
.columnRight > span:nth-child(3) {
  width: 17px;
}
.columnRight > span:nth-child(5) {
  width: 76px;
}
.columnRight > span:nth-child(6) {
  width: 50px;
}
.columnRight > span:nth-child(9) {
  margin-bottom: 8px;
}
.columnLeft {
  width: 19px;
}
.columnLeft > span {
  margin-right: 16px;
  width: 100%;
}
.columnLeft > span:nth-child(4) {
  background-color: transparent;
  margin: 3px 0;
}
.columnContent {
  align-items: flex-start;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
}
.textMessage {
  background: #d9d9d9;
  border-radius: 2px;
  display: block;
  height: 2px;
  margin-bottom: 2px;
}
.likeButton {
  background-color: #003b58;
  border-radius: 2px;
  display: block;
  height: 6px;
  margin-top: 8px;
  width: 23px;
}
.buttonFail {
  margin-left: auto;
}
@keyframes shimmer {
  0% {
    background-position: 100% 0;
  }
  100% {
    background-position: -100% 0;
  }
}
