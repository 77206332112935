.proof-order-modal .modal-header .header-info {
  border-bottom: solid 1px #dfe6e9;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.proof-order-modal .modal-header .header-info img {
  width: 70px;
  height: 70px;
  margin-right: 27.3px;
}
.proof-order-modal .modal-header .header-info ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.proof-order-modal .modal-header .header-info ul li:first-of-type span {
  color: #21201f;
  font-weight: 700;
  font-size: 24px;
}
.proof-order-modal .modal-header .header-info ul li:last-of-type p {
  color: #767676;
  font-size: 14px;
}
.proof-order-modal .modal-header .header-info p {
  font-size: 16px;
  line-height: 1.38;
  color: #505050;
}
.proof-order-modal .modal-header .header-info-mobile {
  border-bottom: solid 1px #dfe6e9;
}
.proof-order-modal .modal-header .header-info-mobile img {
  width: 35px;
  height: 35px;
  margin-right: 17.3px;
  margin-bottom: 11px;
}
.proof-order-modal .modal-header .header-info-mobile ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.proof-order-modal .modal-header .header-info-mobile ul li:first-of-type span {
  color: #21201f;
  font-weight: 700;
  font-size: 18px;
}
.proof-order-modal .modal-body .table {
  margin-bottom: 0;
}
.proof-order-modal .modal-body .table > thead:first-child > tr:first-child > th {
  border: 0;
  padding: 0 0 10px 10px;
}
.proof-order-modal .modal-body .table > thead:first-child > tr:first-child > th span {
  color: #505050;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: 1.21;
  text-transform: uppercase;
}
.proof-order-modal .modal-body .table tbody tr {
  background-color: #f7f7f7;
}
.proof-order-modal .modal-body .table tbody tr td {
  padding: 10px;
}
.proof-order-modal .modal-body .table tbody tr td:nth-child(2) {
  padding: 10px 10px 10px 20px;
}
.proof-order-modal .modal-body .disclaimer {
  color: #767676;
  font-size: 12px;
  margin: 10px 0 0 10px;
}
