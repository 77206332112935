/* stylelint-disable selector-pseudo-class-no-unknown */
.tagContainer {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  justify-content: flex-start;
  max-width: 100%;
}
@media (max-width: 991px) {
  .tagContainer {
    min-height: 12px;
  }
}
@media (max-width: 767px) {
  .tagContainer {
    flex-wrap: nowrap;
    overflow-x: auto;
  }
}
.tagClearAll {
  color: #0e0e0e;
  cursor: pointer;
  font-family: 'circular', serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1;
  padding: 6px;
  text-decoration: underline;
  white-space: nowrap;
}
.tagClearAll:hover {
  background-color: transparent;
  color: #4a4a4a;
  text-decoration: underline;
}
