@media screen and (max-width: 767px) {
}
@media (min-width: 768px) {
}
@media (min-width: 768px) {
}
@media (max-width: 767px) {
}
@media (min-width: 768px) {
}
@media (min-width: 768px) {
}
@media (max-width: 767px) {
}
@media (max-width: 767px) {
}
.horizontal-alignment {
  left: 50%;
  transform: translateX(-50%);
}
.bleed,
.bleed__inner-wrapper {
  bottom: 0pt;
  left: 0pt;
  position: absolute;
  right: 0pt;
  top: 0pt;
}
.bleed__inner-wrapper {
  overflow: hidden;
}
.bleed__inner-wrapper--is-rounded {
  border-radius: 52px;
}
.bleed__line {
  position: absolute;
  border: 1px dashed #003b58;
  z-index: 10;
  top: 0;
  left: 0;
  font-size: 12px;
  outline-color: rgba(0, 0, 0, 0.28);
  outline-style: solid;
}
.bleed__line:after {
  content: ' ';
  position: absolute;
  height: 100%;
  width: 100%;
  border: 1px dashed #fff;
}
.bleed__line .graphic {
  color: #505050;
  left: calc(50% - 140px);
  position: absolute;
  text-align: center;
  top: -30px;
  width: 300px;
  z-index: 11;
}
.bleed__line .graphic.with-tooltip {
  left: calc(50% - 50px);
  width: 100px;
}
.bleed__line .graphic .text {
  background-color: #fff;
  border: 1px solid #d9d9d9;
  padding: 2px;
  border-radius: 10px;
  position: relative;
  height: 20px;
  width: 100%;
  font-size: 12px;
  font-weight: 400;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.bleed__line .graphic .text--is-underlined {
  text-decoration: underline;
}
.bleed__line .graphic .line {
  border-left: 1px solid #000;
  height: 8px;
  left: calc(50% - 1px);
  position: relative;
}
.bleed__line .graphic .circle {
  border-radius: 2px;
  border: 1px solid #000;
  background-color: #fff;
  position: relative;
  height: 5px;
  left: calc(50% - 2.5px);
  width: 5px;
}
.bleed__line [data-tooltip]:hover:before,
.bleed__line [data-tooltip]:hover:after,
.bleed__line [data-tooltip]:focus:before,
.bleed__line [data-tooltip]:focus:after,
.bleed__line .tooltip:hover:before,
.bleed__line .tooltip:hover:after,
.bleed__line .tooltip:focus:before,
.bleed__line .tooltip:focus:after,
.bleed__line .tooltip-top:hover:before,
.bleed__line .tooltip-top:hover:after,
.bleed__line .tooltip-top:focus:before,
.bleed__line .tooltip-top:focus:after {
  transition: all 500ms ease-out;
  transition-delay: 0s;
}
.bleed__line .tooltip-bottom:after {
  margin-left: -115px;
  width: 230px;
  border-radius: 3px;
  font-size: 12px;
}
.bleed__line .tooltip-bottom:before,
.bleed__line .tooltip-bottom:after {
  left: calc(50% + 2px);
}
.bleed__infoBubble {
  left: 50%;
  transform: translateX(-50%);
  background: #fff;
  border-radius: 4px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  display: inline-block;
  color: #505050;
  font-size: 12px;
  line-height: 16px;
  max-width: 318px;
  padding: 4px 8px;
  position: absolute;
  top: -25px;
  width: 100%;
  z-index: 11;
}
.bleed__infoBubble:before {
  left: 50%;
  transform: translateX(-50%);
  background: #21201f;
  bottom: -17px;
  content: ' ';
  height: 17px;
  position: absolute;
  width: 2px;
}
.bleed__infoBubble:after {
  left: 50%;
  transform: translateX(-50%);
  background: #fff;
  border: 1px solid #21201f;
  border-radius: 100%;
  bottom: -20px;
  content: ' ';
  position: absolute;
  height: 6px;
  width: 6px;
}
.bleed__modal {
  max-width: 720px;
}
