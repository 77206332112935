/* stylelint-disable no-descending-specificity */
/**
 * @prettier
 */
@media screen and (max-width: 767px) {
}
@media (min-width: 768px) {
}
@media (min-width: 768px) {
}
@media (max-width: 767px) {
}
@media (min-width: 768px) {
}
@media (min-width: 768px) {
}
@media (max-width: 767px) {
}
@media (max-width: 767px) {
}
.toggle {
  align-items: center;
  flex-direction: row;
  margin: auto 0 auto 0;
  transform: scale(0.75);
}
.toggle :global(.zola-ui.zola-ui__toggle-container .react-toggle .react-toggle-track) {
  height: 32px;
  width: 60px;
}
.toggle :global(.zola-ui.zola-ui__toggle-container .react-toggle .react-toggle-thumb) {
  height: 28px;
  left: 2px;
  top: 2px;
  width: 28px;
}
.toggle :global(.zola-ui.zola-ui__toggle-container
      .react-toggle.react-toggle--checked
      .react-toggle-thumb) {
  left: 30px;
}
.toggle :global(.zola-ui.zola-ui__toggle-container .react-toggle .react-toggle-track-check) {
  left: 4px;
}
.toggle :global(.zola-ui.zola-ui__toggle-container .react-toggle .react-toggle-track-check) :global(.zola-ui__toggle-option-label i) {
  font-size: 18px;
}
.toggle :global(.zola-ui.zola-ui__toggle-container .react-toggle .react-toggle-track-x) {
  right: -1px;
  top: -3px;
}
@media (max-width: 768px) {
  .toggle :global(.zola-ui.zola-ui__toggle-container .react-toggle .react-toggle-track-x) {
    top: 3px;
  }
}
.toggle :global(.zola-ui.zola-ui__toggle-container .react-toggle .react-toggle-track-x) :global(.zola-ui__toggle-option-label i.zola-ui-icon-close) {
  font-size: 27px;
}
.toggle :global(.zola-ui.zola-ui__toggle-container .zola-ui__toggle-button-inline) {
  height: 32px;
}
.inline {
  display: inline-block;
}
.toggleLabelBox {
  align-items: center;
  display: flex;
  font-size: 16px;
  font-weight: 600;
}
.customizationButtonBox {
  display: inline-block;
  min-width: 245px;
}
@media (max-width: 767px) {
  .customizationButtonBox {
    margin-top: 24px;
    width: 100%;
  }
}
@media (max-width: 767px) {
  .stickyCustomizationButtonBox {
    margin-top: 0;
  }
}
.plpTileBoxNew {
  background-size: cover;
  border-radius: 4px;
  color: #003b58;
  height: 420px;
  padding: 32px 20px;
}
@media screen and (max-width: 991px) {
  .plpTileBoxNew {
    background-color: #d9edbf;
    border-radius: 8px;
    height: auto;
    margin: 0 auto;
    max-width: 400px;
    padding: 0;
  }
}
.plpTileMobileImg {
  background-repeat: no-repeat;
  background-size: cover;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
@media screen and (max-width: 991px) {
  .plpTileMobileImg {
    height: 197px;
  }
}
@media screen and (max-width: 767px) {
  .plpTileMobileImg {
    height: 140px;
  }
}
.plpTileTitleNew {
  font-family: 'new-spirit', serif;
  font-size: 24px;
  font-weight: 500;
  line-height: 28px;
}
@media screen and (max-width: 991px) {
  .plpTileTitleNew {
    font-family: 'circular', serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    margin: 14px 15px 12px;
  }
}
.plpTileListBoxNew {
  margin: 20px 0 12px;
}
.plpTileListNew {
  font-size: 14px;
  font-weight: 600;
}
.plpTileListItem {
  display: flex;
  line-height: 22px;
  margin-bottom: 8px;
}
.plpTileListNumber {
  align-items: center;
  background-color: #fff;
  border-radius: 50%;
  display: flex;
  font-size: 16px;
  height: 32px;
  justify-content: center;
  margin-right: 8px;
  min-width: 32px;
}
.plpTileListText {
  padding-top: 5px;
}
.plpTileLink {
  margin: 0 15px 20px;
}
.plpTileBox {
  background-color: #c8e3ec;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 4px;
  color: #003b58;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 40px 20px;
}
@media screen and (max-width: 767px) {
  .plpTileBox {
    margin: auto;
    margin-bottom: 44px;
    max-width: 400px;
  }
}
.plpTileTitle {
  font-size: 24px;
  font-weight: 600;
}
@media screen and (max-width: 1199px) {
  .plpTileTitle {
    font-size: 20px;
  }
}
@media screen and (max-width: 991px) {
  .plpTileTitle {
    font-size: 16px;
  }
}
@media screen and (max-width: 767px) {
  .plpTileTitle {
    align-items: center;
    display: flex;
    flex-direction: row;
    font-size: 24px;
    justify-content: space-between;
  }
}
.plpTileListBox {
  margin: 20px 0;
}
@media screen and (max-width: 1199px) {
  .plpTileListBox {
    margin: 12px 0;
  }
}
@media screen and (max-width: 767px) {
  .plpTileListBox {
    margin: 40px 0;
  }
}
.plpTileList {
  font-size: 20px;
  list-style-type: decimal;
  padding: 0 0 0 20px;
}
.plpTileList li {
  margin: 0 0 12px 0;
  position: relative;
}
@media screen and (max-width: 1199px) {
  .plpTileList {
    font-size: 16px;
  }
}
@media screen and (max-width: 767px) {
  .plpTileList {
    font-size: 24px;
  }
}
.learnMoreInfoIcon {
  margin-right: 4px;
}
.cropBox {
  align-items: center;
  display: flex;
  flex: 1 1 100%;
  flex-direction: column;
  padding: 20px 0;
}
.tileArrowBox {
  display: inline-block;
  height: 36px;
  position: absolute;
  right: 30px;
  top: -36px;
  width: 36px;
}
.tileArrow {
  position: absolute;
  width: 100%;
}
.tutorialModalBox {
  display: flex;
  flex-direction: column;
}
.tutorialModalText {
  margin: 28px 40px;
}
.tutorialModalButtonBox {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin: 0 40px 40px 0;
}
.tutorialModalButton {
  max-width: 180px;
}
