.container {
  width: auto;
}
.modalContent {
  align-items: center;
  display: flex;
  justify-content: space-between;
}
@media (max-width: 767px) {
  .modalContent {
    flex-direction: column;
  }
}
.modalContent :global(.modal-header) {
  padding: 30px 20px 0;
}
.title {
  font-family: 'circular', serif;
  font-size: 24px;
  font-weight: 600;
  line-height: 28px;
  margin-left: 0;
}
.text {
  color: #505050;
  font-size: 16px;
  line-height: 24px;
}
.imgContainer {
  padding: 20px 20px 0 0;
}
@media (max-width: 767px) {
  .imgContainer {
    padding: 0;
  }
}
.svg {
  height: 170px;
}
