.container {
  padding: 44px 38px;
  display: flex;
  flex-direction: column;
}
.container :global p {
  color: #505050;
  font-size: 16px;
  line-height: 28px;
}
.container :global a {
  font-weight: 600;
}
.title {
  font-weight: 600;
  font-size: 24px;
  line-height: 24px;
  margin-top: 0;
  margin-bottom: 20px;
}
.checkmarkContainer {
  height: 28px;
  display: flex;
  align-items: center;
  margin-right: 6px;
  color: #15845d;
}
.noMargin {
  margin-bottom: 0;
}
.bulletListContainer {
  margin-top: 16px;
  margin-bottom: 4px;
}
.bulletItemContainer {
  display: flex;
}
.bulletText {
  margin-bottom: 12px;
}
.lastPara {
  margin-top: 20px;
  margin-bottom: 32px;
}
.button {
  align-self: flex-end;
}
