@media screen and (max-width: 767px) {
}
@media (min-width: 768px) {
}
@media (min-width: 768px) {
}
@media (max-width: 767px) {
}
@media (min-width: 768px) {
}
@media (min-width: 768px) {
}
@media (max-width: 767px) {
}
@media (max-width: 767px) {
}
.detail-box {
  border: 1px solid #d9d9d9;
  background-color: #fff;
  font-family: 'circular', serif;
  padding: 12px;
  border-radius: 4px;
  width: 220px;
}
@media (max-width: 767px) {
  .detail-box {
    width: 100%;
  }
}
.detail-box__img {
  width: 100%;
}
.detail-box__title {
  color: #505050;
  font-size: 20px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  margin-top: 8px;
}
.detail-box__sub-title {
  font-family: 'circular', serif;
  font-size: 14px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #505050;
  margin-left: 8px;
}
.detail-box__price {
  font-size: 16px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #c7133e;
  margin-top: 8px;
}
.detail-box__description {
  font-size: 16px;
  font-weight: 200;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #505050;
  margin-top: 12px;
}
.detail-box__link {
  font-family: 'circular', serif;
  font-size: 16px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #0075ae;
  text-decoration: none;
  cursor: pointer;
  margin-top: 12px;
  display: block;
}
.detail-box__link:hover {
  text-decoration: none;
}
.detail-box__img-wrapper {
  position: relative;
}
.detail-box__img-label {
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  background-color: #fff;
  padding: 5px 8px;
  position: absolute;
  top: 8px;
  left: 8px;
  display: flex;
  align-items: center;
}
.detail-box__img-text {
  font-family: 'circular', serif;
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #21201f;
  margin-top: 2px;
}
.detail-box__star-img {
  margin-right: 4px;
}
