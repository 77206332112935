.crossSellPreviewContainer {
  width: 150px;
  height: 150px;
  padding: 12px;
}
.samplePreviewCustomOverride {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 0;
}
.samplePreviewCustomOverride [class*='circleWrapper'][class*='active'] {
  border-color: #aaaaaa !important;
}
@media (min-width: 768px) and (max-width: 991px) {
  .samplePreviewCustomOverride {
    height: 196px;
  }
}
@media (max-width: 767px) {
  .samplePreviewCustomOverride {
    height: 100%;
  }
}
.sampleModuleContainer {
  padding: 20px 16px;
}
@media (min-width: 992px) {
  .sampleModuleContainer {
    max-width: 404px;
  }
}
@media (min-width: 1200px) {
  .sampleModuleContainer {
    max-width: 33%;
  }
}
.sampleModuleContainer h3 {
  font-size: 32px;
  line-height: 36px;
}
@media (min-width: 768px) {
  .sampleModuleContainer [class*='textContainer'] {
    margin-right: 20px;
  }
}
@media (max-width: 767px) {
  .sampleModuleContainer [class*='textContainer'] {
    align-items: center;
    text-align: center;
  }
}
.sampleModuleContainer [class*='title'] {
  font-size: 24px;
  line-height: 28px;
}
.sampleModuleContainer [class*='subtitle'] {
  font-size: 16px;
  line-height: 20px;
}
@media (max-width: 767px) {
  .sampleModuleContainer {
    margin-left: 20px;
    margin-right: 20px;
  }
}
