#alert-modal .modal-dialog .modal-content .modal-close {
  color: #01b4c0;
}
#alert-modal .modal-dialog .modal-content .modal-close:hover {
  color: #179caa;
}
#alert-modal .modal-dialog .modal-content .modal-action {
  margin: 0 auto;
  margin-top: 30px;
  text-align: center;
}
#alert-modal .modal-dialog .modal-content .modal-header {
  padding-top: 0;
}
#alert-modal .modal-dialog .modal-content .modal-body {
  padding: 0 30px 30px;
}
