@media screen and (max-width: 767px) {
}
@media (min-width: 768px) {
}
@media (min-width: 768px) {
}
@media (max-width: 767px) {
}
@media (min-width: 768px) {
}
@media (min-width: 768px) {
}
@media (max-width: 767px) {
}
@media (max-width: 767px) {
}
.modal-custom {
  margin: 0 auto !important;
  width: 335px !important;
}
@media (max-width: 480px) {
  .modal-custom {
    width: 100% !important;
  }
}
.rsvp-success-modal {
  background-color: #ffffff;
  height: 440px;
  padding: 20px;
  width: 335px;
}
@media (max-width: 480px) {
  .rsvp-success-modal {
    width: 100% !important;
  }
}
.rsvp-success-modal--is-bigger {
  height: 500px;
}
.rsvp-success-modal__header {
  margin: 0 0 30px 0;
  text-align: center;
}
.rsvp-success-modal__body {
  text-align: center;
}
.rsvp-success-modal__title {
  color: #21201f;
  font-family: 'circular', serif;
  font-size: 30px;
  font-stretch: normal;
  font-style: normal;
  font-weight: 100;
  letter-spacing: normal;
  line-height: normal;
  text-align: center;
}
.rsvp-success-modal__illustration {
  height: 149px;
  width: 149px;
}
.rsvp-success-modal__actions {
  margin: 60px 0 0 0;
}
.rsvp-success-modal__actions__button {
  width: 100%;
}
.rsvp-success-modal__actions__button + .rsvp-success-modal__actions__button {
  margin: 20px 0 0 0;
}
