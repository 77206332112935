.other-registries__delete-modal {
  padding: 20px 40px 35px 30px;
}
.other-registries__delete-modal .other-registries__delete-modal__header {
  color: #21201f;
  font-family: 'circular', serif;
  font-size: 30px;
  font-weight: 400;
  margin-bottom: 20px;
}
.other-registries__delete-modal p {
  margin-bottom: 30px;
}
.other-registries__delete-modal button {
  margin-bottom: 12px;
  width: 285px;
}
@media (min-width: 767px) {
  .other-registries__delete-modal button {
    margin-bottom: 0;
    margin-left: 6px;
    margin-right: 6px;
    width: 210px;
  }
}
.other-registries__delete-modal .other-registries__delete-modal_ctas {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 30px;
}
@media (min-width: 767px) {
  .other-registries__delete-modal .other-registries__delete-modal_ctas {
    flex-direction: row;
    justify-content: flex-end;
  }
}
