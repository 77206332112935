@media screen and (max-width: 767px) {
}
@media (min-width: 768px) {
}
@media (min-width: 768px) {
}
@media (max-width: 767px) {
}
@media (min-width: 768px) {
}
@media (min-width: 768px) {
}
@media (max-width: 767px) {
}
@media (max-width: 767px) {
}
.tips__modal {
  padding: 40px 60px;
}
.tips__modal p,
.tips__modal h3 {
  color: inherit;
}
.tips__modal p {
  font-weight: 400;
}
.tips__modal .tips__title {
  color: #21201f;
  font-family: 'circular', serif;
  font-size: 24px;
  font-weight: 600;
  margin: 0;
}
.tips__modal .tips__dek {
  color: #505050;
  font-size: 16px;
  line-height: 1.75;
  margin-top: 24px;
}
.tips__modal .tips__dek > p > a {
  font-weight: 600;
}
.tips__modal .tips__dek strong {
  font-weight: 600;
}
.tips__modal .tips__dek ol li {
  font-weight: 600;
  margin-top: 15px;
}
.tips__modal .tips__dek ol li p {
  font-weight: 400;
}
