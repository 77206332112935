.modal {
  min-height: 615px;
}
.ctaBoxContainer {
  padding-top: 33px;
}
@media (max-width: 767px) {
  .ctaBoxContainer {
    padding-top: 66px;
  }
}
.ctaBox {
  align-items: center;
  border: solid 1px #d9d9d9;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  padding: 1em 0;
  width: 480px;
}
.ctaBox .cta {
  flex: 1;
}
.ctaBox .texts {
  align-items: center;
  flex: 2;
  font-family: 'circular', serif;
}
@media (max-width: 767px) {
  .ctaBox .texts {
    flex: 1;
  }
}
.addedToCart {
  color: #15845d;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 0;
  margin-left: 20px;
}
.subTotal {
  color: #767676;
  font-size: 16px;
  margin-bottom: 0;
  margin-left: 44px;
}
@media (max-width: 767px) {
  .subTotal {
    margin-left: 20px;
  }
}
.donTCheckoutWithoutThese {
  color: #21201f;
  font-family: 'new-spirit', serif;
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 0;
  margin-left: 30px;
  margin-right: 30px;
}
.shopItemsContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
  margin-left: 30px;
  margin-right: 30px;
}
@media (max-width: 991px) {
  .shopItemsContainer {
    flex-wrap: wrap;
    padding-bottom: 24px;
  }
}
