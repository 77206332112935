.seoSection {
  margin-top: 30px;
  padding: 120px 40px;
}
@media (max-width: 991px) {
  .seoSection {
    padding: 60px 40px;
  }
}
@media (max-width: 767px) {
  .seoSection {
    padding: 80px 20px;
  }
}
.header {
  color: #000;
  font-family: 'new-spirit', serif;
  font-size: 32px;
  font-weight: 500;
  line-height: 36px;
  text-align: center;
}
@media screen and (max-width: 991px) {
  .header {
    margin-bottom: 40px;
    text-align: left;
  }
}
.seoAccordionContainer {
  border-bottom: 1px solid #b7b7b7;
  width: 100%;
}
.seoAccordionHeader {
  align-items: center;
  background-color: transparent;
  border-bottom: none;
  border-top: 1px solid #b7b7b7;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 20px;
  width: 100%;
}
.seoAccordionTitle {
  color: #0e0e0e;
  font-family: 'circular', serif;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  font-weight: 600;
  letter-spacing: normal;
  line-height: 24px;
  margin-left: 20px;
}
.seoAccordionTitle:hover {
  text-decoration: underline;
}
.seoAccordionTitleActive {
  color: #0e0e0e;
}
.seoAccordionContent {
  color: #4a4a4a;
  font-family: 'circular', serif;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  font-weight: 400;
  height: auto;
  letter-spacing: normal;
  line-height: 28px;
  padding: 0 20px 20px 20px;
}
.seoAccordionContent > p {
  color: #4a4a4a;
  font-family: 'circular', serif;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 28px;
}
.seoAccordionIcon {
  color: #0e0e0e;
  font-size: 16px;
}
.seoAccordionIcon::before {
  font-weight: 600;
}
.seoBlockStyleList {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.seoBlockStyleList a {
  display: block;
  font-size: 0.875em;
  line-height: 1;
  padding: 10px 5px 10px 0;
  width: 33%;
}
@media (max-width: 767px) {
  .seoBlockStyleList {
    text-align: center;
  }
  .seoBlockStyleList a {
    width: 33%;
  }
}
@media (max-width: 559px) {
  .seoBlockStyleList a {
    width: 50%;
  }
}
.goldStar {
  color: gold;
}
.testimonialBlock {
  margin-bottom: 20px;
}
