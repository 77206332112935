.confirmationWrapper {
  margin: auto;
  text-align: center;
  width: 269px;
}
.confirmationIcon {
  margin: 0 auto 22px;
  width: 77px;
}
.confirmationMessage {
  color: #21201f;
  font-family: 'new-spirit', serif;
  font-weight: 500;
  font-size: 32px;
  line-height: 36px;
  margin-bottom: 22px;
}
.buttonsWrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 32px;
}
