.seo-content-block-container {
  padding-top: 30px;
}
.seo-content-block-container h3,
.seo-content-block-container h4,
.seo-content-block-container h5 {
  font-weight: 600;
}
.seo-content-block-container h5 {
  font-size: 16px;
  letter-spacing: 0;
}
.seo-content-block-container ul,
.seo-content-block-container ol,
.seo-content-block-container p {
  color: #505050;
  font-size: 16px;
  font-weight: 400;
}
.seo-content-block-container__preauth {
  padding: 0;
  max-width: 1200px;
  margin: auto;
}
.seo-content-block-container .seo-content-block__programs-stats-key {
  font-weight: 600;
}
