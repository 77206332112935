/* stylelint-disable selector-pseudo-class-no-unknown, declaration-no-important */
.sidebarContent {
  padding: 40px;
}
.sidebarContent :global(.zola-ui.v2-render-selector-field .field-wrapper) {
  margin-bottom: 12px;
}
.sidebarContent :global(.field-wrapper-inner) {
  align-items: center;
}
.sidebarContent :global(.zola-ui.v2-render-selector-field .selector-icon-wrapper) {
  height: 20px;
  margin-right: 10px;
  min-width: 20px;
  width: 20px;
}
.sidebarContent :global(.selector-label) {
  font-size: 16px;
  letter-spacing: normal;
  line-height: 1.42857143;
  text-transform: none;
}
@media screen and (max-width: 991px) {
  .sidebarContent {
    padding: 32px 20px 70px 20px;
  }
}
.toggleContainer {
  align-items: center;
  display: flex;
  justify-content: space-between;
}
.toggleContainer :global(.zola-ui.zola-ui__toggle-container.zola-ui__toggle-container--inline) {
  height: 32px;
}
.toggleContainer :global(.zola-ui.zola-ui__toggle-container .react-toggle-track) {
  height: 32px;
  width: 60px;
}
.toggleContainer :global(.zola-ui.zola-ui__toggle-container .react-toggle-thumb) {
  height: 28px;
  left: 2px;
  top: 2px;
  width: 28px;
}
.toggleContainer :global(.zola-ui.zola-ui__toggle-container
      .react-toggle.react-toggle--checked
      .react-toggle-thumb) {
  left: 30px;
}
.toggleContainer :global(.zola-ui.zola-ui__toggle-container .react-toggle-track-check) {
  left: 4px;
}
.toggleContainer :global(.zola-ui.zola-ui__toggle-container .react-toggle-track-check) :global(.zola-ui__toggle-option-label i) {
  font-size: 18px;
}
.toggleContainer :global(.zola-ui.zola-ui__toggle-container .react-toggle-track-x) {
  right: -1px;
  top: -3px;
}
@media (max-width: 768px) {
  .toggleContainer :global(.zola-ui.zola-ui__toggle-container .react-toggle-track-x) {
    top: 3px;
  }
}
.toggleContainer :global(.zola-ui.zola-ui__toggle-container .react-toggle-track-x) :global(.zola-ui__toggle-option-label i.zola-ui-icon-close) {
  font-size: 27px;
}
.toggleText {
  color: #505050;
  font-family: 'circular', serif;
  font-size: 16px;
  line-height: 24px;
  margin-right: 30px;
}
.filterTitle {
  color: #21201f;
  font-family: 'circular', serif;
  font-size: 20px;
  line-height: 20px;
  margin-bottom: 14px;
}
.filterSection {
  margin-bottom: 28px;
}
.filterSection:last-of-type {
  margin-bottom: 0;
}
.filterActions {
  align-items: center;
  border-top: 1px solid #eaeaea;
  display: flex;
  justify-content: space-between;
  padding: 20px 40px;
}
@media screen and (max-width: 991px) {
  .filterActions {
    background-color: #ffffff;
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
  }
}
.clearBtn {
  border-color: transparent !important;
  box-shadow: none !important;
  color: #505050 !important;
}
.clearBtn:hover {
  background-color: #eee !important;
}
.clearBtnDisabled {
  background-color: transparent !important;
  color: #d9d9d9 !important;
}
.clearBtnDisabled:hover {
  background-color: transparent !important;
}
.fullWidth {
  width: 100% !important;
}
.clearBtnMobile {
  background-color: transparent !important;
}
.sidedrawerOverride :global(h3) {
  font-size: 20px;
  font-weight: 400;
  margin-left: 0;
  margin-top: 18px;
}
.sidedrawerOverride :global(.zola-ui-icon-close) {
  font-size: 20px;
  left: 388px;
  top: 16px;
}
.sidebarHeader {
  align-items: center;
  display: flex;
  height: 100%;
  width: 100%;
}
.sidebarHeaderButton {
  color: #21201f !important;
  display: flex;
  flex: 0 0 auto;
  padding: 12px;
}
.sidebarHeaderTitle {
  color: #21201f;
  flex: 1 1 auto;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
}
.sidebarActions {
  background-color: #f8f8f8;
  border-top: 1px solid #b7b7b7;
  display: flex;
  justify-content: space-between;
  padding: 12px 20px;
}
@media screen and (max-width: 991px) {
  .sidebarActions {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
  }
}
.sidebarActionsButton {
  flex: 1 0 0;
}
.sidebarActionsButton:not(:last-of-type) {
  margin-right: 12px;
}
