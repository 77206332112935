.imagePlaceholder {
  margin-bottom: 12px;
}
.singleSampleLinkBox {
  visibility: hidden;
}
@media (max-width: 991px) {
  .singleSampleLinkBox {
    visibility: visible;
  }
}
.link:hover {
  cursor: pointer;
  /* stylelint-disable-next-line declaration-no-important */
  text-decoration: none !important;
}
.image {
  overflow: visible;
  position: relative;
  text-align: center;
}
.image .imageContent {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.favoriteToggle {
  position: absolute;
  /* top and left spacing is 12px */
  right: 0;
  top: 0;
}
@media (min-width: 480px) {
  .favoriteToggle {
    right: 8px;
    top: 8px;
  }
}
.imagePlaceholder::after {
  content: '';
  display: block;
  padding-bottom: 100%;
}
.productName {
  font-size: 14px;
  color: #0e0e0e;
  font-weight: 700;
}
@media (min-width: 480px) {
  .productName {
    font-size: 16px;
  }
}
.productNameSection {
  align-items: center;
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-bottom: 16px;
  column-gap: 5px;
  row-gap: 4px;
}
@media (min-width: 480px) {
  .productNameSection {
    row-gap: 8px;
  }
}
.productPricing {
  color: #c7133e;
  margin-bottom: 12px;
}
.selectedSample {
  border: 2px solid #0075ae;
}
.tag {
  text-transform: none;
}
.tag:last-of-type {
  margin-right: 0;
}
.tileLinkBox {
  position: relative;
}
.multiSampleCheckbox {
  margin: 5px;
  position: absolute;
  top: 0;
}
.listingDetail {
  max-width: 400px;
  min-height: 300px;
  text-align: center;
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
}
@media (max-width: 767px) {
  .listingDetail {
    margin-left: auto;
    margin-right: auto;
  }
}
.listingDetail:hover .singleSampleLinkBox {
  visibility: visible;
}
.listingDetail:hover .productName {
  color: #4a4a4a;
  text-decoration: underline;
}
.listingDetail.disabledSampleTile {
  opacity: 0.5;
}
.listingDetail.leftAlign {
  text-align: left;
}
.listingDetail.leftAlign a {
  text-decoration: none;
}
.listingDetail.leftAlign .dropShadow {
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
}
.digitalFree {
  color: #505050;
  font-size: 16px;
  font-weight: 100;
  line-height: 20px;
  margin-bottom: 8px;
}
.digitalBubble {
  background-color: #733658;
  border-radius: 50%;
  left: 4px;
  padding: 3px;
  position: absolute;
  top: 4px;
  z-index: 2;
}
.digitalBubbleInner {
  align-items: center;
  background-color: #733658;
  border: 1px solid #ffffff;
  border-radius: 50%;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  font-size: 9px;
  font-weight: 400;
  height: 46px;
  justify-content: center;
  line-height: 9px;
  width: 46px;
}
.tileBottomRow {
  align-items: flex-end;
  display: flex;
  height: auto;
  margin-top: auto;
  padding-top: 16px;
  width: 100%;
}
@media (min-width: 480px) {
  .tileBottomRow {
    height: 44px;
    margin-top: 32px;
  }
}
.noMarginTop {
  margin-top: 0;
}
.tilePrice {
  width: 100%;
  color: #0e0e0e;
  margin-bottom: 0;
  font-size: 14px;
}
@media (min-width: 480px) {
  .tilePrice {
    font-size: initial;
  }
}
.tileButtonRow {
  display: none;
  width: 100%;
}
.tileButtonRowInnerWrapper {
  display: flex;
}
.tileButtonRowInnerWrapper > * {
  flex: 1;
}
.tileButtonRowInnerWrapper > * + * {
  margin-left: 8px;
}
:global(.card-listing__v2):hover .tilePrice {
  display: initial;
}
@media (min-width: 480px) {
  :global(.card-listing__v2):hover .tilePrice {
    display: none;
  }
}
:global(.card-listing__v2):hover .tileButtonRow {
  display: none;
}
@media (min-width: 480px) {
  :global(.card-listing__v2):hover .tileButtonRow {
    display: block;
  }
}
:global(.card-listing__v2) .image {
  margin-bottom: 24px;
}
