.photoDetailsModal {
  padding: 40px;
}
.photoDetailsTitle {
  font-size: 24px;
  line-height: 28px;
  color: #21201f;
  font-weight: 600;
  margin: 0 0 32px 0;
}
.photoDetailsFormInnerContainer {
  align-items: flex-start;
  display: flex;
  flex-flow: row;
  flex-wrap: nowrap;
  justify-content: center;
  max-width: 100%;
  width: 100%;
}
.photoDetailsPreviewContainer {
  width: 300px;
}
.photoDetailsContainer {
  max-width: 473px;
  padding-left: 40px;
  width: 100%;
}
.photoDetailsCaption label {
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
  margin-bottom: 8px;
  text-transform: none;
}
.photoDetailsFormActions {
  margin-top: 32px;
  text-align: right;
}
.photoDetailsChangeRemove {
  margin-top: 42px;
}
.photoDetailsFormError {
  color: #c7133e;
  margin: -10px 0 0 0;
}
.photoDetailsButton {
  font-size: 16px;
  line-height: 24px;
  align-items: center;
  background-color: transparent;
  border: none;
  box-shadow: none;
  color: #505050;
  display: inline-flex;
  flex-flow: row;
  font-weight: 600;
  margin: 9px 0 0 0;
  padding: 0;
  text-transform: none;
}
.photoDetailsButton:hover {
  background-color: transparent;
  color: #505050;
}
.photoDetailsButton span {
  display: inline-block;
  padding-left: 8px;
}
