/**
 * @prettier
 */
@media screen and (max-width: 767px) {
}
@media (min-width: 768px) {
}
@media (min-width: 768px) {
}
@media (max-width: 767px) {
}
@media (min-width: 768px) {
}
@media (min-width: 768px) {
}
@media (max-width: 767px) {
}
@media (max-width: 767px) {
}
.textBox {
  margin: 12px 0;
}
.backButtonBox {
  margin: auto;
  margin-top: 20px;
}
.orderListingBox {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin: -12px;
}
.orderTileBox {
  justify-content: space-between;
  margin: 12px;
}
.orderTilePreview {
  align-items: center;
  border-radius: 4px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  margin: auto;
  min-height: 225px;
}
.orderTileDetails {
  padding: 12px 0;
}
.orderTileTitle {
  font-size: 16px;
  font-weight: 600;
}
.orderTileDate {
  font-size: 12px;
}
.orderTileLink {
  margin-top: 12px;
}
.box {
  display: flex;
  flex-direction: column;
  justify-items: center;
  padding: 40px;
}
