.card {
  box-shadow: 0 1px 8px 1px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  height: 385px;
  margin-top: 24px;
  width: 200px;
}
.image {
  height: 200px;
  width: 200px;
}
.shopItemText {
  color: #21201f;
  font-family: 'circular', serif;
  font-size: 14px;
  height: 50px;
  letter-spacing: normal;
  line-height: 1.21;
  margin: 12px 12px 5px;
  width: 177px;
}
.title {
  font-size: 14px;
  font-weight: 600;
}
.description {
  font-size: 14px;
  font-weight: 400;
  margin: 0;
}
.price {
  color: #c7133e;
  font-family: 'circular', serif;
  font-size: 16px;
  line-height: 1.38;
  margin-bottom: 5px;
  margin-left: 12px;
  width: 51px;
}
.freeShipping {
  color: #767676;
  font-family: 'circular', serif;
  font-size: 12px;
  letter-spacing: 0.05px;
  margin-bottom: 16px;
  margin-left: 12px;
}
.actionsContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin-left: 12px;
  margin-right: 12px;
}
.amountInputContainer {
  flex: 1;
}
.addToCartButtonContainer {
  flex: 2;
}
.addToCartButton {
  padding: 0;
  width: 100%;
}
.numericInput {
  -moz-appearance: textfield;
  background-color: #ffffff;
  border: solid 2px #d9d9d9;
  border-radius: 4px;
  color: #505050;
  font-family: 'circular', serif;
  font-size: 16px;
  height: 44px;
  padding-left: 6px;
  padding-right: 6px;
  text-align: center;
  width: 44px;
}
.numericInput::-webkit-outer-spin-button,
.numericInput::-webkit-inner-spin-button {
  -webkit-appearance: none;
  display: none;
}
.addedToCartMessage {
  align-items: center;
  color: #15845d;
  display: flex;
  font-family: 'circular', serif;
  font-size: 16px;
  font-weight: 600;
  height: 100%;
  justify-content: center;
  line-height: 1.25;
  max-height: 44px;
  text-align: center;
}
