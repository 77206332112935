.modal {
  display: flex;
  padding-bottom: 44px;
  padding-left: 44px;
  padding-right: 44px;
}
@media (max-width: 767px) {
  .modal {
    padding-left: 22px;
    padding-right: 22px;
    padding-bottom: 22px;
  }
}
.body {
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  max-width: 99.99%;
}
.cta {
  font-weight: 600;
}
.title {
  color: #21201f;
  font-family: 'circular', serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  margin-bottom: 32px;
  margin-top: 44px;
}
.title.single {
  margin-bottom: 8px;
}
.actions {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 32px;
}
.separator {
  height: 1em;
  width: 1em;
}
