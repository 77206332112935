.cardImage {
  position: relative;
  width: 100%;
  padding: 10px;
  background-color: white;
  border-radius: 4px;
}
.card {
  width: 220px;
}
@media (max-width: 767px) {
  .card {
    width: 145px;
  }
}
.cardActions {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  cursor: pointer;
}
.cardActions .cardMobileActions {
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
@media (max-width: 767px) {
  .cardActions .cardMobileActions {
    display: block;
  }
}
@media (min-width: 768px) {
  .cardActions:hover .cardDesktopActions {
    display: flex;
  }
}
.cardActions .cardDesktopActions {
  display: none;
  background-color: rgba(255, 255, 255, 0.4);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  flex-direction: column;
  justify-content: flex-end;
  padding: 20px 10px;
}
.cardActions .cardDesktopActions > button {
  margin-top: 12px;
  padding-left: 10px;
  padding-right: 10px;
}
.cardInDraftTag {
  position: absolute;
  top: 0;
  left: 0;
  background-color: white;
  border-radius: 4px;
  color: #15845d;
  font-weight: 600;
  font-size: 14px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  line-height: 16px;
  padding: 4px 8px;
}
.cardInDraftTag > i {
  margin-right: 6px;
}
.cardInfo {
  margin-top: 16px;
}
.cardName {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  margin-bottom: 8px;
}
.cardPrices {
  color: #b20033;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 8px;
}
.favoriteToggle {
  position: absolute;
  right: 10px;
  top: 10px;
}
.existingDraftsForCardSuiteModal {
  width: 552px;
  height: 479px;
}
