.title {
  color: #21201f;
  font-family: 'circular', serif;
  font-size: 24px;
  font-weight: 600;
  line-height: 28px;
  margin: 0;
}
.text {
  color: #505050;
  font-family: 'circular', serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin: 12px 0 0;
}
.text + .text {
  font-weight: 600;
}
.fieldWrapper {
  margin: 20px 0 0;
}
.cta {
  width: 100%;
}
.errorWrapper {
  margin: 10px 0;
}
.errorWrapper :global(.zola-ui.banner) {
  padding: 12px 20px;
}
.grecaptchaBadge {
  padding-top: 30px;
}
