@media screen and (max-width: 767px) {
}
@media (min-width: 768px) {
}
@media (min-width: 768px) {
}
@media (max-width: 767px) {
}
@media (min-width: 768px) {
}
@media (min-width: 768px) {
}
@media (max-width: 767px) {
}
@media (max-width: 767px) {
}
.delete-project-draft {
  padding: 40px;
  text-align: left;
}
.delete-project-draft h2 {
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 26px;
}
.delete-project-draft p {
  color: #7f7f7f;
}
.delete-project-draft-body {
  margin-bottom: 40px;
}
.delete-project-draft-body .delete-project-draft-dek,
.delete-project-draft-body .delete-confirmation-label {
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 20px;
}
.delete-project-draft-body .delete-project-draft-dek strong,
.delete-project-draft-body .delete-confirmation-label strong {
  font-weight: 700;
  color: #414142;
}
.delete-project-draft-footer {
  overflow: auto;
}
.delete-project-draft-footer:after {
  content: '';
  clear: both;
}
.delete-project-draft-footer .action-buttons {
  float: right;
}
.delete-project-draft-footer .action-buttons button {
  margin-left: 10px;
}
@media (max-width: 767px) {
  .delete-project-draft-footer .action-buttons button {
    width: 100%;
    margin: 5px 0;
    padding-left: 0;
    padding-right: 0;
  }
}
