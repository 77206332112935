/* stylelint-disable no-descending-specificity */
/* stylelint-disable selector-pseudo-class-no-unknown, declaration-no-important */
.black {
  background-color: black;
}
.blue {
  background-color: blue;
}
.brown {
  background-color: brown;
}
.green {
  background-color: green;
}
.grey {
  background-color: grey;
}
.orange {
  background-color: orange;
}
.pink {
  background-color: pink;
}
.purple {
  background-color: #dda0dd;
}
.red {
  background-color: red;
}
.white {
  background-color: white;
}
.yellow {
  background-color: yellow;
}
.beige {
  background-color: beige;
}
.dusty_blue {
  background-color: #889bae;
}
.royal_blue {
  background-color: #4169e1;
}
.navy {
  background-color: navy;
}
.burgundy {
  background-color: #800020;
}
.lavender {
  background-color: lavender;
}
.plum {
  background-color: #843179;
}
.rose {
  background-color: #ff0080;
}
.dusty_rose {
  background-color: #dcae96;
}
.coral {
  background-color: coral;
}
.peach {
  background-color: #ffe5b4;
}
.emerald_green {
  background-color: #008080;
}
.teal {
  background-color: #50c878;
}
.gold {
  background-color: gold;
}
.silver {
  background-color: silver;
}
.rose_gold {
  background-color: #b76e79;
}
.multi {
  background: radial-gradient(50% 50% at 50% 50%, #fff 0%, rgba(255, 255, 255, 0) 100%), conic-gradient(from -90deg at 50% 50%, #c0f -3.06deg, #f00 21.31deg, #f90 56.93deg, #ffe600 105.68deg, #fff500 141.31deg, #3f0 186.31deg, #0ff 236.94deg, #00f7ff 250.06deg, #000aff 293.19deg, #c0f 356.94deg, #f00 381.31deg);
}
.filterCheckbox {
  margin-bottom: 12px;
}
@media (max-width: 991px) {
  .filterCheckbox {
    margin-bottom: 20px;
  }
}
.filterCheckbox:last-of-type {
  margin-bottom: 0;
}
.filterCheckbox label {
  font-size: 16px;
  text-transform: none;
}
.filterCheckbox :global(.field-wrapper-inner) {
  align-items: center;
}
.filterCheckbox:global(.zola-ui.v2-render-selector-field .selector-icon-wrapper) {
  height: 20px !important;
  margin-right: 10px;
  min-width: 20px !important;
  width: 20px !important;
}
.filterCheckbox:global(.zola-ui.v2-render-selector-field .selector-icon-wrapper) i {
  font-size: 14px;
}
.filterCheckbox :global(.selector-label) {
  font-size: 16px;
  letter-spacing: normal;
  line-height: 1.42857143;
  text-transform: none;
}
.colorTagContainer {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: start;
}
.colorTag {
  border-radius: 50%;
  display: block;
  height: 16px;
  margin-right: 8px;
  width: 16px;
}
.colorTagWhite {
  border: 1px solid #d9d9d9;
}
.toggleContainer {
  align-items: center;
  display: flex;
  justify-content: space-between;
}
.toggleContainer :global(.zola-ui.zola-ui__toggle-container.zola-ui__toggle-container--inline) {
  height: 32px;
}
.toggleContainer :global(.zola-ui.zola-ui__toggle-container .react-toggle-track) {
  height: 32px;
  width: 60px;
}
.toggleContainer :global(.zola-ui.zola-ui__toggle-container .react-toggle-thumb) {
  height: 28px;
  left: 2px;
  top: 2px;
  width: 28px;
}
.toggleContainer :global(.zola-ui.zola-ui__toggle-container
      .react-toggle.react-toggle--checked
      .react-toggle-thumb) {
  left: 30px;
}
.toggleContainer :global(.zola-ui.zola-ui__toggle-container .react-toggle-track-check) {
  left: 4px;
}
.toggleContainer :global(.zola-ui.zola-ui__toggle-container .react-toggle-track-check) :global(.zola-ui__toggle-option-label i) {
  font-size: 18px;
}
.toggleContainer :global(.zola-ui.zola-ui__toggle-container .react-toggle-track-x) {
  right: -1px;
  top: -3px;
}
@media (max-width: 768px) {
  .toggleContainer :global(.zola-ui.zola-ui__toggle-container .react-toggle-track-x) {
    top: 3px;
  }
}
.toggleContainer :global(.zola-ui.zola-ui__toggle-container .react-toggle-track-x) :global(.zola-ui__toggle-option-label i.zola-ui-icon-close) {
  font-size: 27px;
}
@media (max-width: 991px) {
  .toggleContainer {
    border-bottom: 1px solid #d9d9d9;
    padding-bottom: 32px;
  }
}
@media (min-width: 768px) {
  .toggleContainer :global(.react-toggle) {
    transform: scale(1.3);
  }
}
.toggleText {
  color: #505050;
  font-family: 'circular', serif;
  font-size: 16px;
  line-height: 24px;
  margin-right: 30px;
}
.fitlerTitle {
  color: #21201f;
  font-family: 'circular', serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 14px;
}
@media (max-width: 991px) {
  .fitlerTitle {
    margin-top: 32px;
  }
  .fitlerTitle:first-of-type {
    margin-top: 0;
  }
}
.filterSection {
  margin-bottom: 28px;
}
@media (max-width: 991px) {
  .filterSection {
    border-bottom: 1px solid #d9d9d9;
    padding-bottom: 32px;
  }
}
.filterSection:last-of-type {
  margin-bottom: 0;
}
@media (max-width: 991px) {
  .filterSection:last-of-type {
    border-bottom: none;
    padding-bottom: 120px;
  }
}
.imgFilterSection {
  display: flex;
  flex-wrap: nowrap;
  margin-bottom: 28px;
}
@media (max-width: 991px) {
  .imgFilterSection {
    border-bottom: 1px solid #d9d9d9;
    padding-bottom: 32px;
  }
}
.imgFilterSection:last-of-type {
  margin-bottom: 0;
}
@media (max-width: 991px) {
  .imgFilterSection:last-of-type {
    border-bottom: none;
    padding-bottom: 120px;
  }
}
.newBadge {
  background: #15845d;
  border-radius: 5px;
  color: #ffffff;
  display: inline-block;
  font-size: 12px;
  font-weight: 600;
  height: 20px;
  line-height: 14px;
  margin-left: 8px;
  padding: 3px 7px;
  position: relative;
}
.shapesContent {
  align-items: center;
  column-gap: 16px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  row-gap: 20px;
}
@media (min-width: 991px) {
  .shapesContent {
    row-gap: 10px;
  }
}
