.matchingItemPreview {
  color: #767676;
  font-size: 16px;
  line-height: 16px;
  padding: 40px;
  position: relative;
}
@media screen and (max-width: 767px) {
  .matchingItemPreview {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.matchingItemCardType {
  color: #21201f;
  display: flex;
  font-family: 'new-spirit', serif;
  font-weight: 500;
  font-size: 32px;
  justify-content: space-between;
  line-height: 32px;
  margin-top: 6px;
}
.matchingItemPreviewImg {
  margin: 20px 0;
}
.addToDraftsCTA {
  display: flex;
  font-weight: 600;
  justify-content: flex-end;
  margin-top: 20px;
  width: 100%;
}
@media screen and (max-width: 767px) {
  .addToDraftsCTA {
    margin-top: 32px;
  }
  .addToDraftsCTA :global(.v2-button) {
    flex-grow: 1;
  }
}
.arrow {
  background-color: #ffffff;
  border: 2px solid #d9d9d9;
  border-radius: 50%;
  color: #0075ae;
  height: 42px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 42px;
}
.arrow:disabled {
  color: #d9d9d9;
}
.icon {
  margin-top: 2px;
}
.leftArrow {
  left: -52px;
}
@media screen and (max-width: 767px) {
  .leftArrow {
    left: 12px;
  }
}
.leftArrow .icon {
  margin-right: 2px;
  transform: rotate(90deg);
}
.rightArrow {
  right: -52px;
}
@media screen and (max-width: 767px) {
  .rightArrow {
    right: 12px;
  }
}
.rightArrow .icon {
  margin-left: 2px;
  transform: rotate(-90deg);
}
.draftsConfirmation {
  color: #15845d;
  width: 200px;
}
@media screen and (max-width: 767px) {
  .draftsConfirmation {
    margin: auto;
  }
}
.draftConfirmationText {
  display: flex;
  flex-direction: column;
  height: 44px;
  justify-content: center;
  text-align: right;
}
@media screen and (max-width: 767px) {
  .draftConfirmationText {
    height: 52px;
    text-align: center;
  }
}
.draftsConfirmationHover {
  color: #0075ae;
  cursor: pointer;
}
.checkmark {
  margin-right: 8px;
}
