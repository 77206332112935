.container {
  padding-top: 40px;
  padding-bottom: 80px;
  background-color: #f9fafc;
  background-image: url('../../../../assets/images/dashboard/confetti_modal.svg');
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.title {
  font-size: 32px;
  line-height: 44px;
  font-family: 'new-spirit', serif;
  font-weight: 500;
  text-align: center;
}
.subtitle {
  text-align: center;
  font-size: 20px;
  line-height: 32px;
  color: #505050;
  font-weight: 600;
  margin: 40px;
}
