@media screen and (max-width: 767px) {
}
@media (min-width: 768px) {
}
@media (min-width: 768px) {
}
@media (max-width: 767px) {
}
@media (min-width: 768px) {
}
@media (min-width: 768px) {
}
@media (max-width: 767px) {
}
@media (max-width: 767px) {
}
.image-layer-container {
  overflow: visible;
  position: absolute;
  /* Prevent pointer events on non-customizable images so we don't block the hover state of other elements the image overlaps */
}
.image-layer-container .element-drag-handle {
  align-items: center;
  background: #55a1bf;
  border-radius: 100%;
  color: #ffffff;
  cursor: move;
  display: flex;
  height: 28px;
  justify-content: center;
  left: calc(50% - 14px);
  position: absolute;
  top: -36px;
  width: 28px;
  z-index: 1000;
}
.image-layer-container .element-resize-handle {
  display: none;
}
.image-layer-container:not(.isEditable) {
  pointer-events: none;
}
.image-layer-container.isEditable:not(.isActive) {
  cursor: pointer;
  touch-action: none;
}
@media screen and (min-width: 992px) {
  .image-layer-container:not(.isBusy):hover .image-layer-overlay.controls {
    display: block;
  }
  .image-layer-container:not(.isBusy):hover .image-layer-controls {
    display: flex;
  }
}
@media screen and (max-width: 991px) {
  .image-layer-container.isActive .image-layer-overlay.controls {
    display: block;
  }
  .image-layer-container.isActive .image-layer-controls {
    display: flex;
  }
}
.image-layer-container .image-layer-preview {
  left: 0;
  position: absolute;
  top: 0;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.image-layer-container .image-layer-preview.stretch {
  height: 100%;
  width: 100%;
}
.image-layer-container .placeholder-image {
  background: #f3f3f3;
  height: 100%;
  position: absolute;
  width: 100%;
}
.image-layer-container .image-layer-content {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
.image-layer-container .image-layer-controls {
  align-items: center;
  bottom: 0;
  display: none;
  justify-content: space-evenly;
  left: 0;
  padding: 18px;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 10;
}
.image-layer-container .image-layer-controls.isDragging {
  opacity: 0;
}
.image-layer-container .image-layer-controls .image-layer-button {
  align-items: center;
  background: none;
  border: none;
  color: #fff;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  outline: none;
  padding: 0 5px;
  text-align: center;
  text-shadow: 0 1px black;
}
.image-layer-container .image-layer-controls .image-layer-button.draggable {
  cursor: grab;
}
.image-layer-container .image-layer-controls .image-layer-button .zolaicon {
  font-size: 24px;
}
.image-layer-container .image-layer-controls .image-layer-button span {
  font-size: 10px;
  font-weight: 500;
  line-height: 16px;
}
@media screen and (max-width: 480px) {
  .image-layer-container .image-layer-controls .image-layer-button span {
    font-size: 13px;
  }
}
.image-layer-container .image-layer-overlay {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
.image-layer-container .image-layer-overlay.controls {
  background-color: rgba(0, 0, 0, 0.6);
  display: none;
}
.image-layer-container .image-layer-overlay.isDragging {
  background-color: #6c6c6c;
}
.image-layer-container .image-layer-overlay.isOver {
  background-color: rgba(255, 255, 255, 0.4);
}
.image-layer-container .image-layer-overlay.canDrop {
  border: 1px dashed #0075ae;
  margin: -4px;
}
.image-layer-container .image-layer-overlay.isBusy {
  align-items: center;
  background-color: rgba(255, 255, 255, 0.4);
  display: flex;
}
.image-layer-container .image-layer-overlay.loading {
  align-items: center;
  background-color: #f3f3f3;
  display: flex;
}
.image-layer-container .coordinate-line {
  display: flex;
  position: absolute;
  z-index: 10;
}
.image-layer-container .coordinate-line .coordinate-line__value {
  align-items: center;
  background-color: white;
  background-color: rgba(51, 96, 129, 0.55);
  color: white;
  display: flex;
  font-family: 'circular', serif;
  font-size: 12px;
  height: 15px;
  letter-spacing: normal;
  line-height: normal;
  padding-left: 3px;
  padding-right: 3px;
}
.image-layer-container .coordinate-line.coordinate-line--vertical {
  align-items: center;
  border-left: 1px solid #336081;
  width: 1px;
}
.image-layer-container .coordinate-line.coordinate-line--horizontal {
  border-bottom: 1px solid #336081;
  height: 1px;
  justify-content: center;
}
.image-layer-container .coordinate-line.coordinate-line--horizontal .coordinate-line__value {
  bottom: 0;
  position: absolute;
}
