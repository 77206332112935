.datePickerV2StyleOverride .zui__datepicker {
  min-width: 134px;
  position: relative;
}
.datePickerV2StyleOverride input {
  border: 2px solid #d9d9d9;
  border-radius: 4px;
  box-sizing: border-box;
  color: #505050;
  font-size: 16px;
  font-weight: 400;
  height: 44px;
  padding: 14px 12px 14px 32px;
  text-overflow: ellipsis;
  width: 100%;
}
@media (max-width: 767px) {
  .datePickerV2StyleOverride input {
    height: 52px;
  }
}
.datePickerV2StyleOverride input:focus {
  border: 2px solid #0075ae;
}
.datePickerV2StyleOverride input::placeholder {
  color: #aaaaaa;
}
.datePickerV2StyleOverride input:disabled {
  background-color: #f7f7f7;
  color: #d9d9d9;
}
.datePickerV2StyleOverride .zolaicon-calendar {
  margin: 10px;
  position: absolute;
  top: 0;
}
.datePickerV2StyleOverride .zolaicon-calendar.error {
  fill: #b20033;
}
.datePickerV2StyleOverride .zolaicon-calendar.success {
  fill: #15845d;
}
.datePickerV2StyleOverride .text-danger {
  color: #b20033;
}
.datePickerV2StyleOverride .text-warning {
  color: #ff9a00;
}
.datePickerV2StyleOverride .text-success {
  color: #15845d;
}
.zola-ui.v2-render-selector-field .datePickerV2StyleOverride label {
  color: #21201f;
  cursor: default;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: normal;
  text-transform: capitalize;
}
