.container h1,
.containerWide h1 {
  font-family: 'new-spirit', serif;
  font-weight: 500;
}
.container {
  margin: 19px;
  padding-bottom: 100px;
  position: relative;
  width: auto;
}
@media (min-width: 768px) {
  .container {
    margin: 0 auto;
    padding-top: 40px;
    width: 365px;
  }
}
.containerWide {
  margin: 19px;
  padding-bottom: 40px;
  padding-top: 20px;
  position: relative;
  width: auto;
}
@media (min-width: 768px) {
  .containerWide {
    margin: 0 auto;
    width: 440px;
  }
}
.title {
  color: #21201f;
  font-family: 'new-spirit', serif;
  font-weight: 500;
  margin: 20px 0;
  text-align: center;
}
.field {
  margin-bottom: 20px;
}
.error {
  color: #b20033;
  font-size: 14px;
  margin-bottom: 12px;
  margin-top: 12px;
  text-align: center;
}
.submit {
  margin: 20px 0;
}
.textCenter {
  text-align: center;
}
