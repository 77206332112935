@media screen and (max-width: 767px) {
}
@media (min-width: 768px) {
}
@media (min-width: 768px) {
}
@media (max-width: 767px) {
}
@media (min-width: 768px) {
}
@media (min-width: 768px) {
}
@media (max-width: 767px) {
}
@media (max-width: 767px) {
}
.add-guest-group-modal {
  padding-bottom: 24px;
  padding-top: 24px;
}
@media (min-width: 768px) {
  .add-guest-group-modal {
    padding-bottom: 30px;
    padding-top: 30px;
  }
}
@media (min-width: 992px) {
  .add-guest-group-modal {
    padding-bottom: 40px;
    padding-top: 40px;
  }
}
.add-guest-group-modal .modal__header > h3 {
  font-family: 'new-spirit', serif;
  font-weight: 500;
}
.add-guest-group-modal .modal__header,
.add-guest-group-modal .horizontal-nav {
  padding-left: 20px;
  padding-right: 20px;
}
@media (min-width: 768px) {
  .add-guest-group-modal .modal__header,
  .add-guest-group-modal .horizontal-nav {
    padding-left: 30px;
    padding-right: 30px;
  }
}
@media (min-width: 992px) {
  .add-guest-group-modal .modal__header,
  .add-guest-group-modal .horizontal-nav {
    padding-left: 60px;
    padding-right: 60px;
  }
}
.add-guest-group-modal .modal__body {
  margin-top: 9px;
}
@media (min-width: 768px) {
  .add-guest-group-modal .modal__body {
    margin-top: 12px;
  }
}
.add-guest-group-modal .tabs-content,
.add-guest-group-modal .form__group {
  margin-top: 24px;
}
@media (min-width: 767px) {
  .add-guest-group-modal .tabs-content,
  .add-guest-group-modal .form__group {
    margin-top: 30px;
  }
}
.add-guest-group-modal .modal__title {
  color: #21201f;
  font-family: 'circular', serif;
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 0.2px;
  margin-bottom: 0;
  margin-top: 0;
}
.add-guest-group-modal .add-guest-tab {
  margin-left: 20px;
  margin-right: 20px;
}
@media (min-width: 768px) {
  .add-guest-group-modal .add-guest-tab {
    margin-left: 30px;
    margin-right: 30px;
  }
}
@media (min-width: 992px) {
  .add-guest-group-modal .add-guest-tab {
    margin-left: 60px;
    margin-right: 60px;
  }
}
.add-guest-group-modal .upload-csv__tab {
  margin: 30px 24px 0;
}
.add-guest-group-modal .upload-csv__tab.mobile {
  text-align: center;
}
@media (min-width: 767px) {
  .add-guest-group-modal .upload-csv__tab {
    margin-left: 40px;
    margin-right: 40px;
  }
}
.add-guest-group-modal .horizontal-nav {
  margin-bottom: 0;
}
.add-guest-group-modal .horizontal-nav .row {
  margin-left: 0;
  margin-right: 0;
}
.add-guest-group-modal .horizontal-nav .tabs-labels {
  margin: 0;
  padding: 0;
  text-align: left;
}
.add-guest-group-modal .horizontal-nav .tabs-labels .tabs-label {
  margin-top: 9px;
  padding: 0 15px 0 0;
}
@media (min-width: 767px) {
  .add-guest-group-modal .horizontal-nav .tabs-labels .tabs-label {
    margin-top: 0;
    padding: 0 30px 0 0;
  }
}
.add-guest-group-modal .horizontal-nav .tabs-labels .tabs-label:last-child {
  margin-right: 0;
  padding-right: 0;
}
.add-guest-group-modal .horizontal-nav .tabs-labels a {
  font-size: 12px;
  line-height: 1.3;
  margin: 0;
  padding: 3px 0;
}
@media (min-width: 767px) {
  .add-guest-group-modal .horizontal-nav .tabs-labels a {
    font-size: 16px;
    padding: 9px 0;
  }
}
.add-guest-group-modal .enter-individually .relationship-to-you {
  width: 100%;
}
@media (min-width: 768px) {
  .add-guest-group-modal .enter-individually .relationship-to-you {
    width: 50%;
  }
}
.add-guest-group-modal .enter-individually .invited-to-wedding .form-label {
  margin-bottom: 0;
}
.add-guest-group-modal .enter-individually .invited-to-wedding label {
  font-weight: 400;
}
.add-guest-group-modal .enter-individually .invited-to-wedding .render-radio-field {
  display: block;
  margin-right: 0;
  margin-top: 12px;
}
.add-guest-group-modal .enter-individually .invited-to-wedding .render-radio-field .zolaicon-info {
  display: none;
}
@media (min-width: 768px) {
  .add-guest-group-modal .enter-individually .invited-to-wedding .render-radio-field {
    display: inline-block;
    margin-right: 20px;
    margin-top: 0;
  }
  .add-guest-group-modal .enter-individually .invited-to-wedding .render-radio-field:last-child {
    margin-right: 0;
  }
}
.add-guest-group-modal .enter-individually .event-invitations {
  align-items: start;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}
@media (min-width: 992px) {
  .add-guest-group-modal .enter-individually .event-invitations {
    align-items: center;
    flex-direction: row;
  }
}
.add-guest-group-modal .enter-individually .event-invitations .form-label {
  margin-bottom: 0;
  width: 100%;
}
.add-guest-group-modal .enter-individually .event-invitations.-disabled {
  filter: opacity(40%);
}
.add-guest-group-modal .enter-individually .event-invitations .render-checkbox-field {
  margin-top: 12px;
}
@media (min-width: 992px) {
  .add-guest-group-modal .enter-individually .event-invitations .render-checkbox-field {
    margin-right: 10px;
    margin-top: 6px;
  }
  .add-guest-group-modal .enter-individually .event-invitations .render-checkbox-field:last-child {
    margin-right: 0;
  }
}
.add-guest-group-modal .enter-individually .event-invitations .render-checkbox-field label {
  letter-spacing: normal;
  text-transform: initial;
}
.add-guest-group-modal .change-template__buttons {
  text-align: center;
}
.add-guest-group-modal .change-template__buttons .zolaicon {
  display: none;
  font-size: 18px;
  margin-right: 10px;
}
@media (min-width: 767px) {
  .add-guest-group-modal .change-template__buttons .zolaicon {
    display: inline-block;
  }
}
.add-guest-group-modal .change-template__buttons .btn {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 12px;
  max-width: 256px;
  padding: 4px 12px;
}
@media (min-width: 767px) {
  .add-guest-group-modal .change-template__buttons .btn {
    display: inline-block;
    margin-top: 0;
    max-width: 190px;
    width: 100%;
  }
  .add-guest-group-modal .change-template__buttons .btn:not(:last-child) {
    border-right: 0;
  }
}
.add-guest-group-modal .change-template__buttons .btn:first-child {
  margin-top: 0;
}
.add-guest-group-modal .change-template__buttons .btn:hover {
  background-color: #ffffff;
  border-color: #0e0e0e;
  color: #0e0e0e;
}
.add-guest-group-modal .with-tooltip .zolaicon-info {
  font-size: 14px;
}
.add-guest-group-modal .form-label {
  font-weight: 700;
}
.add-guest-group-modal .multiple-guests:first-child .guest-number {
  margin-top: 0;
}
@media (max-width: 992px) {
  .add-guest-group-modal .multiple-guests .prefix-field {
    margin-top: 4px;
  }
}
.add-guest-group-modal .guest-number {
  font-family: inherit;
  font-size: 20px;
  font-weight: 400;
  line-height: 1.1;
  margin: 0;
  margin-top: 24px;
}
.add-guest-group-modal .guest-delete {
  bottom: 0;
  color: #0e0e0e;
  cursor: pointer;
  font-size: 14px;
  line-height: 1.2;
  position: absolute;
  right: 10px;
}
@media (min-width: 992px) {
  .add-guest-group-modal .guest-delete {
    bottom: 12px;
    color: #21201f;
    right: -8px;
  }
}
.add-guest-group-modal .guest-unknown__section {
  align-items: center;
  display: flex;
  margin-top: 16px;
}
.add-guest-group-modal .guest-unknown__section label {
  letter-spacing: normal;
  text-transform: initial;
}
.add-guest-group-modal label[for*='add-guest-group-prefix'] {
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 1.2px;
  margin-bottom: 0;
  text-transform: uppercase;
}
@media (min-width: 992px) {
  .add-guest-group-modal label[for*='add-guest-group-prefix'] {
    display: none;
  }
}
.add-guest-group-modal label[for='add-guest-group-prefix-0'] {
  display: inline-block;
}
.add-guest-group-modal .scroll-select {
  margin-top: 4px;
}
.add-guest-group-modal .scroll-select .btn-dropdown {
  color: #21201f;
}
.add-guest-group-modal .scroll-select .btn-dropdown:hover {
  background-color: #ffffff;
  border-color: #0e0e0e;
}
.add-guest-group-modal .scroll-select .zolaicon {
  color: #0e0e0e;
}
.add-guest-group-modal .render-field {
  margin-top: 16px;
}
@media (max-width: 991px) {
  .add-guest-group-modal .prefix-field {
    margin-top: 0;
  }
}
.add-guest-group-modal .plus-one-btn {
  font-size: 14px;
  line-height: 1.1;
}
.add-guest-group-modal .missing-contact-info {
  align-items: start;
  display: flex;
  flex-direction: column;
}
@media (min-width: 768px) {
  .add-guest-group-modal .missing-contact-info {
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  }
}
.add-guest-group-modal .missing-contact-info .missing-contact-info-label {
  color: #21201f;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0.1em;
  line-height: 1.5;
  text-transform: uppercase;
}
.add-guest-group-modal .missing-contact-info .missing-contact-info-btn {
  margin-top: 12px;
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
}
@media (min-width: 768px) {
  .add-guest-group-modal .missing-contact-info .missing-contact-info-btn {
    margin-top: 0;
    width: auto;
  }
}
.add-guest-group-modal .guest-contact-info h4 {
  color: #21201f;
  font-family: 'circular', serif;
  font-size: 21px;
  line-height: 1.5;
  padding-left: 0;
  padding-right: 0;
}
.add-guest-group-modal .guest-contact-info h4:hover {
  background-color: transparent;
}
.add-guest-group-modal .guest-contact-info .accordion-section {
  margin-bottom: 0;
}
.add-guest-group-modal .guest-contact-info .form-group {
  margin-bottom: 12px;
}
.add-guest-group-modal .guest-contact-info label {
  font-weight: 600;
}
.add-guest-group-modal .guest-contact-info .render-field {
  margin-bottom: 12px;
  margin-top: 0;
}
.add-guest-group-modal .guest-contact-info .accordion-body {
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
}
.add-guest-group-modal .guest-contact-info .accordion-body.selected {
  overflow-y: initial;
}
.add-guest-group-modal .add-guest-group-buttons {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
@media (min-width: 768px) {
  .add-guest-group-modal .add-guest-group-buttons {
    flex-direction: row;
    justify-content: flex-end;
  }
}
.add-guest-group-modal .add-guest-group-buttons .button {
  width: 100%;
}
@media (min-width: 768px) {
  .add-guest-group-modal .add-guest-group-buttons .button {
    width: auto;
  }
}
.add-guest-group-modal .add-guest-group-buttons .save-and-close-btn {
  margin-top: 12px;
}
@media (min-width: 768px) {
  .add-guest-group-modal .add-guest-group-buttons .save-and-close-btn {
    margin-left: 15px;
    margin-top: 0;
  }
}
.add-guest-group-modal .send-link-tab,
.add-guest-group-modal .upload-spreadsheet {
  font-family: 'circular', serif;
  margin-left: auto;
  margin-right: auto;
  max-width: 640px;
  text-align: center;
  width: 100%;
}
.add-guest-group-modal .send-link-tab p,
.add-guest-group-modal .upload-spreadsheet p,
.add-guest-group-modal .send-link-tab h3,
.add-guest-group-modal .upload-spreadsheet h3 {
  font-family: inherit;
  margin: 0;
}
.add-guest-group-modal .send-link-tab .tab__description,
.add-guest-group-modal .upload-spreadsheet .tab__description,
.add-guest-group-modal .send-link-tab .tab__input,
.add-guest-group-modal .upload-spreadsheet .tab__input,
.add-guest-group-modal .send-link-tab .tab__buttons,
.add-guest-group-modal .upload-spreadsheet .tab__buttons,
.add-guest-group-modal .send-link-tab .tab__help,
.add-guest-group-modal .upload-spreadsheet .tab__help {
  margin-top: 24px;
}
.add-guest-group-modal .send-link-tab .tab__title,
.add-guest-group-modal .upload-spreadsheet .tab__title {
  color: #21201f;
  font-size: 24px;
}
.add-guest-group-modal .send-link-tab .tab__input {
  border: 1px solid #a8a8aa;
  padding: 15px;
}
@media (max-width: 767px) {
  .add-guest-group-modal .send-link-tab .button {
    display: block;
    width: 100%;
  }
  .add-guest-group-modal .send-link-tab .button:not(:first-child) {
    margin-top: 12px;
  }
}
@media (min-width: 768px) {
  .add-guest-group-modal .send-link-tab .button.copy-link-button {
    margin-right: 15px;
  }
}
.add-guest-group-modal .upload-spreadsheet .tab__help {
  border-top: 1px solid #d9d9d9;
  padding-top: 24px;
}
.add-guest-group-modal .upload-spreadsheet .help__icon {
  float: left;
  max-width: 90px;
  width: 30%;
}
.add-guest-group-modal .upload-spreadsheet .help__text {
  float: left;
  padding-left: 24px;
  text-align: left;
  width: 70%;
}
.add-guest-group-modal .upload-spreadsheet .help__text p {
  color: inherit;
  font-size: 16px;
  line-height: 1.75;
  margin-bottom: 3px;
}
.add-guest-group-modal .upload-spreadsheet .help__text p:last-child {
  margin-bottom: 0;
}
.add-guest-group-modal .upload-spreadsheet .help__text .help__cta {
  color: #21201f;
  font-size: 20px;
  font-weight: 600;
  line-height: 1;
}
.add-guest-group-modal .upload-spreadsheet .tab__buttons .file-input-button-container {
  display: inline-block;
}
.add-guest-group-modal .upload-spreadsheet .tab__buttons .file-input-button-container .btn {
  border-width: 1px;
}
@media (max-width: 767px) {
  .add-guest-group-modal .upload-spreadsheet .tab__buttons .file-input-button-container,
  .add-guest-group-modal .upload-spreadsheet .tab__buttons .file-input-button,
  .add-guest-group-modal .upload-spreadsheet .tab__buttons .btn,
  .add-guest-group-modal .upload-spreadsheet .tab__buttons .button {
    width: 100%;
  }
  .add-guest-group-modal .upload-spreadsheet .tab__buttons .file-input-button {
    margin-bottom: 0;
  }
  .add-guest-group-modal .upload-spreadsheet .tab__buttons .file-input-button-container {
    display: block;
    margin-top: 12px;
  }
  .add-guest-group-modal .upload-spreadsheet .tab__buttons .btn {
    margin-left: 0;
    margin-right: 0;
  }
}
.add-guest-group-modal.upload__success {
  align-items: center;
  background-color: #15845d;
  color: white;
  display: flex;
  flex-direction: column;
  height: 490px;
  justify-content: center;
  text-align: center;
}
.add-guest-group-modal.upload__success > .upload-success__checkmark {
  background: white;
  border-radius: 40px;
  color: green;
  display: inline-block;
  font-size: 38px;
  height: 80px;
  line-height: 80px;
  padding-left: 3px;
  padding-top: 3px;
  width: 80px;
}
.add-guest-group-modal.upload__success > .upload-success__checkmark {
  background: white;
  border-radius: 40px;
  color: #15845d;
  display: inline-block;
  font-size: 38px;
  height: 80px;
  line-height: 80px;
  padding-left: 3px;
  padding-top: 3px;
  width: 80px;
}
.add-guest-group-modal.upload__success > .upload-success__text {
  color: white;
  font-size: 24px;
  margin-top: 28px;
}
.match-guest-list-box {
  padding: 20px;
}
