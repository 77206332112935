.container {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 474px;
  padding: 44px;
  width: 100%;
}
.title {
  margin-bottom: 12px;
  margin-top: 22px;
  text-align: center;
}
.subTitle {
  color: #505050;
  font-family: 'circular', serif;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 28px;
  text-align: center;
}
