.previewContainer {
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-flow: column;
  overflow: hidden;
  width: 100%;
}
@media (min-width: 992px) {
  .previewContainer {
    height: 100vh;
  }
}
.mainContentWrapper {
  align-items: stretch;
  display: flex;
  flex-flow: row nowrap;
  height: 100%;
  justify-content: center;
  width: 100%;
}
@media (max-width: 991px) {
  .mainContentWrapper {
    flex-flow: column;
    justify-content: stretch;
    align-items: center;
  }
}
.additionalTitle {
  color: #505050;
  display: block;
  font-family: 'new-spirit', serif;
  font-size: 32px;
  font-weight: 500;
  line-height: 1.42857143;
  margin-bottom: 20px;
  margin-top: 0;
  word-break: break-word;
}
@media (max-width: 991px) {
  .additionalTitle {
    font-family: 'circular', serif;
    font-weight: 600;
  }
}
.additionalText {
  color: #505050;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.42857143;
  margin-bottom: 16px;
}
@media (max-width: 991px) {
  .additionalText {
    display: inline-block;
    font-weight: 400;
  }
}
.additionalButton {
  font-family: 'circular', serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  padding: 10px 20px;
  width: auto;
}
@media (max-width: 991px) {
  .additionalButton {
    width: 100%;
    font-size: 14px;
  }
}
/* stylelint-disable-next-line selector-pseudo-class-no-unknown */
:global(.v2-button.primary-button:disabled) {
  background-color: #003b58;
}
.additionalInformationWrapper {
  background-color: #ffffff;
  display: flex;
  flex-flow: column;
  justify-content: center;
  padding: 16px 60px 16px;
  width: 35%;
  z-index: 1;
}
@media (max-width: 991px) {
  .additionalInformationWrapper {
    width: 100%;
    max-width: 500px;
    flex-flow: column-reverse;
    align-items: center;
    padding: 16px 22px;
  }
}
.additionalInformation:last-of-type {
  border-top: 1px solid #aaaaaa;
  margin-top: 30px;
  padding-top: 30px;
}
.previewCanvas {
  width: 65%;
}
@media screen and (max-width: 991px) {
  .previewCanvas {
    height: 600px;
    width: 100%;
  }
}
.logoContainer {
  margin-bottom: 30px;
}
@media (max-width: 991px) {
  .logoContainer {
    margin-top: 30px;
  }
}
.additionalSubtitle {
  display: block;
  font-weight: 700;
  margin-bottom: 16px;
}
.additionalSubtitle span {
  margin-left: 4px;
}
@media (max-width: 991px) {
  .additionalSubtitle {
    font-weight: 400;
    display: inline;
  }
}
@media (max-width: 991px) {
  .mobileCapitalize {
    text-transform: capitalize;
  }
}
.thumbnailPreviewContainer {
  background-color: #fff;
  display: flex;
  left: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 90%;
}
.thumbnailImageContainer {
  width: 65%;
}
.thumbnailTextContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2px;
  width: 35%;
}
.thumbnailTitle {
  color: #505050;
  display: block;
  font-family: 'new-spirit', serif;
  font-size: 4px;
  font-weight: 500;
  line-height: 6px;
  margin-bottom: 3px;
  text-align: left;
  word-break: break-word;
}
.thumbnailText {
  color: #505050;
  font-size: 3px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.42857143;
  margin-bottom: 2px;
  text-align: left;
}
.thumbnailSubtitle {
  display: block;
  font-weight: 700;
}
.thumbnailSubtitle span {
  margin-left: 2px;
}
.thumbnailButtonContainer {
  font-size: 2px;
  line-height: 4px;
}
.thumbnailButton {
  /* stylelint-disable-next-line selector-pseudo-class-no-unknown */
}
.thumbnailButton:global(.v2-button) {
  border-radius: 1px;
  cursor: default;
  font-family: 'circular', serif;
  font-size: 2px;
  font-style: normal;
  font-weight: 600;
  height: auto;
  padding: 0 3px;
  width: 100%;
}
.thumbnailInformation:last-of-type {
  margin-top: 2px;
  padding-top: 2px;
}
.searchBar {
  height: 8px;
  left: 0;
  position: absolute;
  top: 8px;
  width: 90%;
}
