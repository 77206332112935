.container {
  border-radius: 4px;
  padding: 40px;
}
.headerImg {
  border-radius: 10px;
  width: 100%;
}
.header {
  color: #21201f;
  font-family: 'circular', serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  margin-bottom: 12px;
  margin-top: 32px;
}
.guestHeader {
  color: #21201f;
  font-family: 'circular', serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  margin-bottom: 12px;
}
.content {
  color: #505050;
  font-family: 'circular', serif;
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  line-height: 24px;
  max-height: 80vh;
}
.buttonContainer {
  align-items: baseline;
  display: flex;
  flex-direction: row;
  justify-content: end;
  margin-top: 40px;
  width: 100%;
}
.buttonSpacing {
  margin-left: 12px;
}
.proTip {
  margin-top: 40px;
}
.tipsList {
  list-style: decimal;
  margin-left: 20px;
}
.digitalTipsContainer {
  border-radius: 4px;
  padding: 40px;
}
@media (max-width: 767px) {
  .digitalTipsContainer {
    padding: 40px 20px;
  }
}
.digitalTipsList {
  margin: 20px 0 20px 0;
  padding-inline-start: 20px;
}
@media (max-width: 767px) {
  .digitalTipsList {
    padding-left: 20px;
  }
}
.templateModalContent {
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
}
@media (max-width: 767px) {
  .templateModalContent {
    display: block;
  }
}
.optionsWrapper,
.templateWrapper {
  width: 50%;
}
@media (max-width: 767px) {
  .optionsWrapper,
  .templateWrapper {
    width: 100%;
  }
}
.optionsWrapper {
  padding-right: 20px;
}
@media (max-width: 767px) {
  .optionsWrapper {
    padding-right: 0;
    margin-bottom: 20px;
  }
}
.templatePreview {
  align-items: center;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  text-align: center;
}
.templatePreview h1 {
  font-size: 1.33em;
}
.templatePreview p,
.templatePreview a {
  font-size: 0.66em;
}
.templatePreviewText {
  max-width: 80%;
}
.templatePreviewSpacer {
  margin-bottom: 20px;
}
.templatePreviewImage {
  margin-bottom: 20px;
  padding: 20px;
  width: 100%;
}
.templatePreviewImage img {
  max-width: 200px;
}
.emailReset {
  line-height: 1.2;
  margin: 0;
}
.emailFieldWrapper {
  display: flex;
}
.emailFieldWrapper > div:first-of-type {
  width: 75%;
}
.emailFieldWrapper > div:last-of-type {
  width: 25%;
}
.fieldCharCount {
  font-size: 12px;
}
.contactInfoList {
  padding-left: 24px;
}
.modalWidthOverride:global(.modal-dialog) {
  max-width: 100%;
  width: 568px !important;
}
.emailProviders {
  margin: 16px 0 30px 0;
}
.emailProviders img {
  margin-right: 12px;
}
.guest {
  padding: 12px;
}
.allContainer {
  align-items: center;
  border: 2px solid #d9d9d9;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.finderInput :global(input) {
  border: none !important;
  padding: 12px !important;
}
.finderInput :global(.input-field__container) {
  margin-top: 0;
}
.selectAllCheck {
  border-right: 1px solid #d9d9d9;
  padding: 12px;
}
.selectAllCheck :global(.selector-label) {
  white-space: nowrap !important;
}
.guestButtonContainer {
  align-items: baseline;
  display: flex;
  flex-direction: row;
  justify-content: end;
  margin-top: 32px;
  width: 100%;
}
@media screen and (max-width: 767px) {
  .guestButtonContainer {
    background-color: #ffffff;
    bottom: 0;
    box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.25);
    display: block;
    left: 0;
    margin-top: 0;
    padding: 20px;
    position: fixed;
    right: 0;
  }
}
.cancelbtn {
  margin-right: 12px;
}
@media screen and (max-width: 767px) {
  .cancelbtn {
    margin-right: 0;
    margin-top: 12px;
  }
}
.progressWrapper {
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  margin-bottom: 40px;
  width: 100%;
}
.infoWrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
  max-width: 422px;
}
.progressContent {
  margin-right: 20px;
}
.progressTitle {
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
}
.progressPercentage {
  margin-left: auto;
}
.progressBar {
  background: #eee;
  border-radius: 16px;
  height: 8px;
  position: relative;
  width: 100%;
}
.progressInner {
  animation-duration: 3.25s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: shimmer;
  animation-timing-function: linear;
  background: #06b025 linear-gradient(to left, #06b025 0%, #06b025 30%, #5faf6c 45%, #c5ddc6 50%, #5faf6c 60%, #06b025 70%, #06b025 100%) no-repeat;
  background-size: 1000px 100%;
  border-radius: 16px;
  display: inline-block;
  height: 100%;
  position: absolute;
  transition: width 0.4s ease;
}
.progressInfo {
  display: flex;
  margin-bottom: 6px;
}
.progressClose {
  background: transparent;
  border: 0;
  color: #a8a8aa;
  cursor: pointer;
  font-size: 26px;
  font-weight: 100;
  height: 26px;
  line-height: 1;
  margin-left: 20px;
  padding: 0;
  width: 26px;
}
.stepOneSubTitle {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 40px;
}
.stepOneWrapper {
  align-items: flex-start;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  width: 100%;
}
.stepOneTitle {
  font-size: 24px;
  font-weight: 600;
  line-height: 28px;
  margin-bottom: 12px;
}
.copyEmailTemplateWWrapper {
  display: flex;
  flex-direction: row;
  height: 44px;
  justify-content: flex-end;
  width: 100%;
}
.guestListCopyContainer {
  border-radius: 4px;
  padding: 40px;
}
@media screen and (max-width: 767px) {
  .guestListCopyContainer {
    border-radius: 0;
    min-width: 100vw;
    overflow-x: hidden;
    overflow-y: scroll;
    padding: 0;
  }
}
@media screen and (max-width: 767px) {
  .guestListHeader {
    background-color: #ffffff;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    font-size: 20px;
    left: 0;
    padding: 10px 20px;
    position: absolute;
    text-align: left;
    top: 0;
    width: 100%;
    z-index: 10;
  }
}
@media screen and (max-width: 767px) {
  .guestListContent {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    overflow-y: scroll;
    padding: 82px 20px 182px 20px;
  }
}
.guestContainer {
  border: 2px solid #d9d9d9;
  border-radius: 4px;
  margin: 12px 0;
  max-height: 50vh;
  overflow-x: hidden;
  overflow-y: scroll;
}
@media screen and (max-width: 767px) {
  .guestContainer {
    border: none;
    max-height: none;
    overflow-x: unset;
    overflow-y: unset;
  }
}
@media screen and (max-width: 767px) {
  .guestListLoader {
    padding: 40px 20px 20px 20px;
  }
}
@media screen and (max-width: 767px) {
  .guesListButtonContainer {
    display: block;
  }
}
.createNewGuestListInfo {
  list-style-type: circle;
}
@media screen and (max-width: 767px) {
  .createNewGuestListInfo {
    padding-inline-start: 20px;
  }
}
.guestDivider {
  background-color: #d9d9d9;
  height: 1px;
  margin: 0 8px;
  width: 100%;
}
@media screen and (max-width: 767px) {
  .guestDivider {
    margin: 0;
    width: 100%;
  }
}
