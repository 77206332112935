.box {
  display: flex;
  flex-direction: row;
  justify-content: center;
  min-height: 512px;
}
.box .form {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-right: 30px;
  max-width: 600px;
  min-width: 350px;
  padding: 0 20px;
  position: relative;
}
.box .form .title {
  display: flex;
  font-family: 'new-spirit', serif;
  font-size: 32px;
  font-weight: 500;
  line-height: 32px;
  margin: 46px 0 8px 0;
}
.box .form .actionsBox {
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  vertical-align: middle;
}
.box .form .actionsBox .counts {
  display: flex;
  flex-direction: row;
  font-size: 16px;
  line-height: 20px;
}
.box .form .actionsBox .counts .complete {
  margin-right: 16px;
}
.box .form .actionsBox .counts .blank {
  color: #767676;
  margin-right: 24px;
}
.box .form .actionsBox .counts .error {
  color: #b20033;
}
.box .form .actionsBox .actions {
  display: flex;
  flex-direction: row;
}
.box .form .actionsBox .actions .importButton {
  margin-right: 12px;
}
.box .form .actionsBox .actions .importButton div {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}
@media (max-width: 991px) {
  .box .form .actionsBox .actions .importButton {
    max-width: 100px;
  }
}
.box .form .actionsBox .actions .meatballMenuButtonBox {
  position: relative;
}
.box .form .actionsBox .actions .meatballMenuButtonBox .meatballMenuButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 0 -1.5px;
  padding: 10px 6px;
}
.box .form .actionsBox .actions .meatballMenuButtonBox .meatballMenuButton .meatBall {
  background: #0075ae;
  border-radius: 7px;
  height: 7px;
  margin: 0 1.5px;
  width: 7px;
}
.box .form .actionsBox .actions .meatballMenuButtonBox .meatballMenuFrame {
  background: #ffffff;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  flex: 1;
  flex-direction: column;
  font-size: 16px;
  font-weight: 600;
  justify-content: center;
  line-height: 40px;
  padding: 12px 0;
  position: absolute;
  right: 0;
  top: 50px;
  width: 170px;
  z-index: 10;
}
.box .form .actionsBox .actions .meatballMenuButtonBox .meatballMenuFrame.disabled {
  cursor: wait;
  pointer-events: none;
}
.box .form .actionsBox .actions .meatballMenuButtonBox .meatballMenuFrame .meatballMenuItem {
  cursor: pointer;
  padding: 0 12px;
  width: 100%;
}
.box .form .actionsBox .actions .meatballMenuButtonBox .meatballMenuFrame .meatballMenuItem:hover {
  background: #f8f8f8;
}
.box .form .actionsBox .actions .meatballMenuButtonBox .meatballMenuFrame .meatballMenuItemDisabled {
  cursor: default;
  opacity: 0.4;
}
.box .form .assignListBox {
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  margin: 12px 0 40px 0;
  pointer-events: all;
}
.box .form .assignListBox.disableInput {
  cursor: wait;
  pointer-events: none;
}
.box .form .assignListBox .listHeader {
  background: #f8f8f8;
  display: flex;
  flex-direction: row;
}
.box .form .assignListBox .listHeader .indexHeaderCell {
  height: 40px;
  width: 55px;
}
.box .form .assignListBox .listHeader .headerCell {
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: row;
  font-weight: 600;
  padding: 0 20px;
}
.box .form .assignListBox .listHeader .headerCell.primarySort {
  text-decoration: underline;
}
.box .form .assignListBox .listHeader .headerCell .sortIcon {
  align-items: center;
  display: flex;
  margin-left: 8px;
}
.box .form .assignListBox .listHeader > .headerCell:hover {
  cursor: pointer;
  text-decoration: underline;
}
.box .form .assignListBox .guestBlock {
  display: flex;
  flex-direction: column;
}
.box .form .assignListBox .guestBlock .guestRow {
  background: #ffffff;
  display: flex;
  flex-direction: row;
}
.box .form .assignListBox .guestBlock .guestRow.error {
  background: #fff9f8;
}
.box .form .assignListBox .guestBlock .guestRow.editing,
.box .form .assignListBox .guestBlock .guestRow:hover {
  background: #f5f7fa;
}
.box .form .assignListBox .guestBlock .guestRow .disabled,
.box .form .assignListBox .guestBlock .guestRow .disabled:hover {
  background: #f8f8f8;
  cursor: not-allowed;
}
.box .form .assignListBox .guestBlock .guestRow.disabled,
.box .form .assignListBox .guestBlock .guestRow.disabled:hover {
  background: #f8f8f8;
  cursor: not-allowed;
}
.box .form .assignListBox .guestBlock .guestRow.disabled > *,
.box .form .assignListBox .guestBlock .guestRow.disabled:hover > * {
  pointer-events: none;
}
.box .form .assignListBox .guestBlock .guestRow .indexCell {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 55px;
}
.box .form .assignListBox .guestBlock .guestRow .guestCell {
  align-items: center;
  cursor: pointer;
  display: flex;
  flex: 1;
  flex-direction: row;
}
.box .form .assignListBox .guestBlock .guestRow .guestCell .cellValue {
  max-width: 200px;
  overflow: hidden;
  padding: 32px 12px;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.box .form .assignListBox .guestBlock .guestRow .guestCell .cellValue.valueError {
  color: #b20033;
}
.box .form .assignListBox .guestBlock .guestRow .guestCell input {
  margin-left: 10px;
  max-width: 180px;
}
.box .form .assignListBox .guestBlock .guestRow .guestCell form {
  align-items: center;
  display: flex;
  flex-direction: row;
}
.box .form .assignListBox .guestBlock .guestRow .guestCell form button {
  margin-left: 10px;
}
.box .form .assignListBox .guestBlock .guestRow .guestCell .hiddenButton {
  height: 0;
  visibility: hidden;
  width: 0;
}
.box .form .assignListBox .guestBlock .guestRow .deleteIcon {
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  position: relative;
}
.box .form .assignListBox .guestBlock .guestRow .deleteIcon img {
  left: 12px;
  max-height: 36px;
  max-width: 36px;
  min-height: 36px;
  min-width: 36px;
  position: absolute;
}
.box .form .assignListBox .indexBorder {
  border: 1px solid #d9d9d9;
  border-width: 0 1px 1px 0;
}
.box .form .assignListBox .indexBorder.last {
  border-width: 0 1px 0 0;
}
.box .form .assignListBox .nameBorder {
  border: 1px solid #d9d9d9;
  border-width: 0 1px 1px 0;
}
.box .form .assignListBox .nameBorder.last {
  border-width: 0 1px 0 0;
}
.box .form .assignListBox .tableBorder {
  border: 1px solid #d9d9d9;
  border-width: 0 0 1px 0;
}
.box .form .assignListBox .tableBorder.last {
  border-width: 0 0 0 0;
}
.box .form .previewBox {
  padding: 0 20px;
}
:local .preview {
  display: flex;
  flex-direction: column;
  margin-top: 90px;
  min-width: 360px;
  pointer-events: none;
  position: relative;
}
:local .preview .previewFixed {
  position: fixed;
}
:local .preview :global(.bleed) {
  display: none;
}
.modal {
  max-width: 700px;
}
.importBox {
  padding-bottom: 1px;
}
.importBox .importHeader {
  background: url('assets/images/invitations/placecard-import-header-banner.svg');
  background-size: cover;
  display: flex;
  flex: 1;
  flex-direction: row;
  width: 100%;
}
.importBox .importHeader .importSteps {
  display: flex;
  flex-direction: row;
  margin: 40px;
  width: 100%;
}
.importBox .importHeader .importSteps .stepItem {
  display: flex;
  flex: 1;
  flex-direction: column;
}
.importBox .importHeader .importSteps .stepItemName {
  color: #21201f;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  margin-bottom: 20px;
}
.importBox .importHeader .importSteps .stepItemName.inactive {
  color: #d9d9d9;
}
.importBox .importHeader .importSteps .stepItemLine {
  background: #003b58;
  border-radius: 4px;
  height: 4px;
  width: 100%;
}
.importBox .importHeader .importSteps .stepItemLine.inactive {
  background: #d9d9d9;
}
.importBox .importBody {
  margin: 40px;
}
.importBox .importBody .stepBox .stepTitle {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 12px;
}
.importBox .importBody .stepBox .stepBody {
  font-size: 16px;
  margin-bottom: 32px;
}
.importBox .importBody .stepBox .templateLinkBox {
  display: flex;
  flex: 1;
  flex-direction: row;
  margin-bottom: 40px;
  margin-top: 12px;
}
.importBox .importBody .stepBox .templateLink {
  margin-right: 20px;
}
.importBox .importBody .stepBox .templateLinkText {
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: row;
  font-size: 16px;
}
.importBox .importBody .stepBox .templateLinkText img {
  margin-right: 8px;
}
.importBox .importBody .stepBox .templateLinkSuccessText {
  align-items: center;
  color: #21201f;
  display: flex;
  flex: 1;
  flex-direction: row;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
}
.importBox .importBody .stepBox .templateLinkSuccessText img {
  margin-right: 8px;
}
.importBox .importBody .stepBox .templateInstructionsBox svg {
  transform: scale(3);
}
.importBox .importBody .stepBox .stepButtonRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 40px;
}
.importBox .importBody .stepBox .stepButtonRow .needHelp {
  align-items: center;
  color: #505050;
  display: flex;
  flex: 1;
  flex-direction: row;
}
.importBox .importBody .stepBox .stepButtonRow .needHelp img {
  margin: 0 4px;
}
.importBox .importBody .stepBox .stepButtonRow .needHelp a.needHelpLink {
  color: #505050;
  font-weight: 600;
  margin-left: 4px;
  text-transform: none;
}
.importBox .importBody .stepBox .fileDropzoneBox {
  align-items: center;
  background: #f5f9fc;
  border: 2px dashed #aaa;
  border-radius: 4px;
  display: flex;
  flex: 1;
  flex-direction: column;
  font-weight: 600;
  height: 144px;
  justify-content: center;
  width: 100%;
}
.importBox .importBody .stepBox .uploadStatusBox {
  align-items: center;
  border-bottom: 1px solid #eee;
  border-top: 1px solid #eee;
  display: flex;
  flex: 1;
  flex-direction: row;
  font-weight: 600;
  padding: 20px 0;
}
.importBox .importBody .stepBox .uploadStatusBox img {
  margin-right: 8px;
}
.warningBox {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 40px;
}
.warningBox .warningTitle {
  font-size: 24px;
  font-weight: 600;
  line-height: 28px;
  margin-bottom: 12px;
}
.warningBox .warningBody {
  margin-bottom: 40px;
}
.warningBox .warningButtonBox {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: flex-end;
}
.warningBox .warningButtonBox :global :local(button.warningButton).v2-button {
  margin: 0 0 0 12px;
}
input[type='file'].hidden,
a.hidden,
.hidden {
  display: none;
}
:local .templateRadioBox {
  display: flex;
  flex: 1;
  flex-direction: column;
}
:local .templateRadioBox :global(.selector-icon-wrapper) {
  margin: 6px 6px 6px 0;
}
:local .templateRadioBox :global(.selector-label-wrapper) {
  margin: 6px 0;
}
:local .templateRadioBox :global(.selector-label-wrapper span.selector-label) {
  font-family: 'circular', serif;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 20px;
  margin: 12px 0;
  text-transform: none;
}
:local .templateRadioBox :global(.selector-label-wrapper span.selector-label.selector-label--checked) {
  font-weight: 400;
}
:local .templateRadioBox .dropdownBox {
  margin-bottom: 12px;
  max-width: 220px;
}
:local .templateRadioBox .label {
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
  margin-top: 6px;
}
:local .templateRadioBox .radioBold {
  font-weight: 600;
}
:local .templateRadioBox .prefilledOptionsBox {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding-left: 40px;
}
