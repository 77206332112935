.lastUpdated {
  color: #767676;
  font-family: 'circular', serif;
  font-size: 14px;
  font-weight: 400;
}
.container {
  display: flex;
  flex-direction: row;
  margin-top: 23px;
}
@media (max-width: 767px) {
  .container {
    flex-direction: column;
  }
}
.imageContainer {
  align-items: flex-start;
  display: flex;
}
.image {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
}
.evenImage {
  height: 222px;
  width: 222px;
}
.portraitImage {
  height: auto;
  width: 222px;
}
.landscapeImage {
  height: auto;
  width: 280px;
}
.customizationsContainer {
  display: flex;
  flex-direction: column;
  margin-left: 22px;
}
@media (max-width: 767px) {
  .customizationsContainer {
    margin-left: 0;
  }
}
@media (max-width: 767px) {
  .customization {
    margin-top: 20px;
  }
}
.customization .title {
  color: #21201f;
  font-family: 'circular', serif;
  font-size: 600;
  font-weight: 600;
  line-height: 1em;
  margin: 0;
}
.customization .amount {
  font-family: 'circular', serif;
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  margin: 0;
  margin-bottom: 8px;
  margin-top: 4px;
}
.customization .label {
  color: #505050;
  font-family: 'circular', serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 8px;
}
.customization .label b {
  color: #21201f;
  font-weight: 600;
}
