.container {
  padding: 45px 40px;
  display: flex;
  flex-direction: column;
}
.container :global h1,
.container :global h4 {
  text-align: center;
}
.container :global h1 {
  margin: 0;
  font-family: 'new-spirit', serif;
  font-weight: 500;
}
.container :global h4 {
  font-size: 16px;
  line-height: 28px;
  color: #505050;
  margin: 20px 0;
}
.stepsSection {
  padding: 32px 48px;
  background-color: #c9d6ec;
  height: 220px;
  margin-bottom: 20px;
  background-image: url('../../../../assets/images/virtualEvents/confetti_modal.svg');
  background-size: auto 100%;
}
.stepsSection :global h3 {
  font-family: 'new-spirit', serif;
  font-weight: 500;
  text-align: center;
  color: #21201f;
  line-height: 32px;
  margin: 0;
}
.stepContainer {
  display: flex;
  margin-top: 40px;
  margin-bottom: 10px;
  justify-content: space-around;
}
.step {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.stepEmoji {
  font-size: 46px;
}
.stepImg {
  width: 48px;
  margin-bottom: 8px;
}
.stepText {
  color: #003b58;
  font-size: 12px;
  font-weight: 600;
}
.btnContainer {
  display: flex;
  flex-direction: row-reverse;
}
.btnPadding {
  margin-right: 12px;
}
