/**
 * @prettier
 */
@media screen and (max-width: 767px) {
}
@media (min-width: 768px) {
}
@media (min-width: 768px) {
}
@media (max-width: 767px) {
}
@media (min-width: 768px) {
}
@media (min-width: 768px) {
}
@media (max-width: 767px) {
}
@media (max-width: 767px) {
}
.title {
  align-self: center;
  color: #21201f;
  font-size: 20px;
  font-weight: 600;
  margin: auto;
  padding: 20px 0;
  text-align: center;
}
.image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100px;
  width: 100%;
}
.body {
  padding: 20px 40px;
}
.button {
  display: flex;
  flex-direction: row-reverse;
}
.buttonWrapper {
  width: 80px;
}
@media (max-width: 767px) {
  .buttonWrapper {
    width: 100%;
  }
}
.price {
  color: #c7133e;
  font-weight: 600;
  padding: 20px 0;
}
.description {
  display: flex;
  flex-direction: row;
  padding: 12px 0;
}
@media (max-width: 767px) {
  .description {
    flex-direction: column;
  }
}
.descriptionTitle {
  color: #767676;
  font-size: 12px;
  font-weight: 600;
  padding-bottom: 8px;
}
.descriptionBody {
  font-size: 14px;
  padding-bottom: 20px;
  padding-right: 12px;
}
@media (max-width: 767px) {
  .descriptionBody {
    font-size: 14px;
  }
}
.box {
  display: flex;
  flex-direction: column;
}
