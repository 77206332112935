@media screen and (max-width: 767px) {
}
@media (min-width: 768px) {
}
@media (min-width: 768px) {
}
@media (max-width: 767px) {
}
@media (min-width: 768px) {
}
@media (min-width: 768px) {
}
@media (max-width: 767px) {
}
@media (max-width: 767px) {
}
.multiple-events-modal {
  padding: 40px;
  text-align: center;
}
.multiple-events-modal .header-img-container {
  margin-bottom: 20px;
}
.multiple-events-modal .header-img {
  display: block;
  margin: 0 auto;
}
.multiple-events-modal .sections {
  margin-bottom: 20px;
}
.multiple-events-modal .super-header {
  font-family: 'circular', serif;
  text-transform: uppercase;
}
.multiple-events-modal .primary-header {
  margin-bottom: 30px;
  margin-top: 0;
}
.multiple-events-modal .section-header {
  font-family: 'circular', serif;
  text-transform: uppercase;
}
