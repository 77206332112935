.start-guest-list__modal {
  background-color: #f7f7f7;
  padding: 20px;
  text-align: center;
}
@media (min-width: 768px) {
  .start-guest-list__modal {
    padding: 40px 80px;
  }
}
.start-guest-list__modal .modal__title {
  font-size: 32px;
  font-weight: 400;
  margin: 0;
}
.start-guest-list__modal .modal__dek {
  font-size: 24px;
  font-weight: 400;
  margin-top: 12px;
}
.start-guest-list__modal .modal__image {
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
}
.start-guest-list__modal .modal__image img {
  width: 100%;
  display: block;
}
.start-guest-list__modal .modal__body,
.start-guest-list__modal .modal__buttons {
  margin-top: 40px;
}
.start-guest-list__modal .modal__buttons {
  text-align: right;
}
.start-guest-list__modal .modal__buttons .link {
  margin-right: 24px;
  font-size: 16px;
  text-transform: initial;
  color: #0075ae;
}
@media (max-width: 480px) {
  .start-guest-list__modal .modal__buttons {
    display: flex;
    flex-direction: column;
  }
  .start-guest-list__modal .modal__buttons .link {
    order: 2;
    margin-right: 0;
    margin-top: 20px;
  }
}
